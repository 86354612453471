import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, TextField, Button, Alert, Modal, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import LogoImage from '../../assets/logo.svg';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ResetPasswordPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const [searchParams] = useSearchParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');
  const [messageType, setMessageType] = useState('success'); // 'success' or 'error'
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>/\\[\]]/.test(password);
    const isValid = password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar;

    let helperText = t('password_criteria');
    if (!isValid) {
      if (password.length < minLength) helperText += t('password_min_length');
      if (!hasUpperCase) helperText += t('password_upper_case');
      if (!hasNumber) helperText += t('password_number');
      if (!hasSpecialChar) helperText += t('password_special_char');
    } else {
      helperText = '';
    }

    setPasswordHelperText(helperText);
    return isValid;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = searchParams.get('token');

    if (!validatePassword(password)) {
      setMessage(t('password_invalid'));
      setMessageType('error');
      return;
    }

    if (password !== confirmPassword) {
      setMessage(t('password_mismatch'));
      setMessageType('error');
      return;
    }

    setLoading(true);
    fetch(`${backendUrl}/api/users/reset-password`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token, password })
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        if (data.success) {
          setMessage(t('password_reset_success'));
          setMessageType('success');
          setModalOpen(true);
        } else {
          setMessage(t('password_reset_failed'));
          setMessageType('error');
        }
      })
      .catch(error => {
        console.error('Erreur lors de la réinitialisation du mot de passe:', error);
        setLoading(false);
        setMessage(t('password_reset_error'));
        setMessageType('error');
      });
  };

  const handleClose = () => {
    window.location.href = 'raoulapp://deep-link/login';  // Deep link redirection vers l'app
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      p: 4,
    }}>
      <img src={LogoImage} alt="Logo" style={{ height: '50px', margin: '0 auto', marginBottom: '2em' }} />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h5" sx={{ mb: 0 }}>{t('reset_password')}</Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label={t('new_password')}
              type={showPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              helperText={passwordHelperText}
            />
            <TextField
              label={t('confirm_new_password')}
              type={showConfirmPassword ? 'text' : 'password'}
              fullWidth
              margin="normal"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle confirm password visibility"
                      onClick={handleClickShowConfirmPassword}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              {t('reset_password_button')}
            </Button>
          </form>
          {message && (
            <Alert severity={messageType} sx={{ mt: 2 }}>
              {message}
            </Alert>
          )}
        </>
      )}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '85%',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            textAlign: 'center',
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2" gutterBottom>
            {t('now_you_can_login')}
          </Typography>
          <Typography id="modal-description" variant="body2" color="textSecondary" sx={{ mb: 2 }}>
            {t('redirect_in_5_seconds')}
          </Typography>
          <Button variant="contained" color="primary" onClick={handleClose}>
            {t('open_app')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );
};

export default ResetPasswordPage;
