import React, { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { useParams, useNavigate } from 'react-router-dom';
import { Modal, Box, Button, Typography, IconButton } from '@mui/material';
import Lottie from 'react-lottie-player';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import pulseAnimation from '../../assets/pulse.json';
import { useTheme } from '@mui/material/styles';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AudioStreamer = () => {
  const { roomCode } = useParams();
  const navigate = useNavigate();
  const audioRef = useRef(null);
  const socketRef = useRef(null);
  const [remainingTime, setRemainingTime] = useState(0);
  const [audioDuration, setAudioDuration] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const theme = useTheme();
  const { currentUser } = useAuth();
  const { t } = useTranslation(); // Hook pour les traductions

  // Nouveaux états pour les paramètres de méditation
  const [meditationType, setMeditationType] = useState(null);
  const [soundBackground, setSoundBackground] = useState(null);
  const [duration, setDuration] = useState(null);

  const updateRemainingTime = () => {
    if (audioRef.current) {
      const timeLeft = audioRef.current.duration - audioRef.current.currentTime;
      setRemainingTime(timeLeft > 0 ? timeLeft : 0);
    }
  };

  const generateAudioURL = (meditationType, soundBackground, duration) => {
    const language = localStorage.getItem('i18nextLng') || 'fr'; // Récupère la langue ou utilise 'fr' par défaut
    const sound = soundBackground ? 'soundtrue' : 'soundfalse';
    const fileName = `${language}/${language}-${meditationType}_${sound}_${duration}.aac`;
    const url = `https://data.heyraoul.fr/audios/timers/${fileName}`;
  
    console.log('Debug - generateAudioURL:', { language, meditationType, soundBackground, duration, sound, url });
    
    return url;
  };
  


  const saveMeditationSession = async () => {
    if (currentUser) {
      const sessionData = {
        userId: currentUser.userId,
        listenedDuration: Math.round(audioRef.current.currentTime / 60),
        sessionType: 'group',
      };
  
      try {
        console.log('Envoi de la requête de sauvegarde de session:', sessionData);
        const response = await fetch(`${backendUrl}/api/meditation-sessions/sessions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(sessionData),
        });
  
        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || 'Erreur lors de la sauvegarde de la session');
        }
  
        const responseData = await response.json();
        console.log('Session de méditation de groupe sauvegardée avec succès', responseData);
  
        if (responseData.session && responseData.session._id) {
          // Enregistrer l'ID de session dans localStorage
          localStorage.setItem('sessionId', responseData.session._id);
          console.log('ID de session enregistré dans localStorage:', responseData.session._id);
        } else {
          console.error('La réponse ne contient pas d\'ID de session valide.');
        }
      } catch (error) {
        console.error('Erreur lors de la sauvegarde de la session de méditation:', error.message);
      }
    }
  };  

  const handleAudioEnd = async () => {
    try {
      await saveMeditationSession();
  
      // Envoyer l'événement et attendre la confirmation
      socketRef.current.emit('endMeditation', { room: roomCode }, (confirmation) => {
        if (confirmation === 'completed') {
          // Naviguer seulement après la confirmation du serveur
          navigate(`/room/${roomCode}`);
        } else {
          console.error('Erreur: la méditation n\'a pas été confirmée comme "completed"');
        }
      });
    } catch (error) {
      console.error('Error in handleAudioEnd:', error);
    }
  };  

  const startAudio = (elapsed) => {
    if (!meditationType || soundBackground === null || soundBackground === undefined || !duration) {
      return;
  }

    const audioURL = generateAudioURL(meditationType, soundBackground, duration);
    audioRef.current.src = audioURL;
    audioRef.current.currentTime = elapsed;

    // console.log.log('Lecture de l’audio à partir de l’URL:', audioURL, 'à partir du temps:', elapsed);

    const playPromise = audioRef.current.play();
    if (playPromise !== undefined) {
      playPromise
        .then(() => {
          // console.log.log('Audio lancé avec succès');
          setIsPlaying(true);
        })
        .catch(error => {
          // console.log.log('Échec du lancement de l’audio:', error);
          setIsPlaying(false);
          setElapsedTime(elapsed);
          setOpenModal(true);
        });
    } else {
      // console.log.error('Élément audio non valide.');
    }
  };

  useEffect(() => {
    socketRef.current = io(backendUrl);

    // console.log.log(`Connexion au serveur via socket pour la room ${roomCode}`);
    socketRef.current.emit('joinRoom', { room: roomCode, token: localStorage.getItem('token') });

    // console.log.log('Demande de l’état actuel de la méditation');
    socketRef.current.emit('getMeditationState', { room: roomCode });

    socketRef.current.on('meditationStateResponse', (state) => {
      console.log('Type et valeur de state.soundBackground à la réception :', typeof state.soundBackground, state.soundBackground);

      // console.log.log('Réponse reçue pour l’état de la méditation:', state);
      setMeditationType(state.meditationType);
      setSoundBackground(state.soundBackground);
      setDuration(state.duration);
    });

    socketRef.current.on('startMeditation', (startTime) => {
      const startTimestamp = new Date(startTime).getTime();
      const elapsed = (Date.now() - startTimestamp) / 1000;
      setElapsedTime(elapsed);
      if (meditationType && soundBackground !== null && duration) {
        // console.log.log('Démarrage de la méditation:', { meditationType, soundBackground, duration });
        startAudio(elapsed);
      }
    });

    socketRef.current.on('joinMeditationInProgress', (startTime) => {
      const startTimestamp = new Date(startTime).getTime();
      const elapsed = (Date.now() - startTimestamp) / 1000;
      setElapsedTime(elapsed);
      if (meditationType && soundBackground !== null && duration) {
        // console.log.log('Rejoindre la méditation en cours:', { meditationType, soundBackground, duration });
        startAudio(elapsed);
      }
    });

    if (audioRef.current) {
      audioRef.current.addEventListener('loadedmetadata', () => {
        setAudioDuration(audioRef.current.duration);
        updateRemainingTime();
      });

      audioRef.current.addEventListener('timeupdate', updateRemainingTime);
      audioRef.current.addEventListener('ended', handleAudioEnd);
    }

    return () => {
      // console.log.log('Déconnexion du socket');
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
      if (audioRef.current) {
        audioRef.current.removeEventListener('loadedmetadata', updateRemainingTime);
        audioRef.current.removeEventListener('timeupdate', updateRemainingTime);
        audioRef.current.removeEventListener('ended', handleAudioEnd);
      }
    };
  }, [roomCode, navigate, meditationType, soundBackground, duration]);

  const handleResumeClick = () => {
    if (audioRef.current && isFinite(elapsedTime)) {
      // console.log.log('Reprise de l’audio à partir du temps:', elapsedTime);
      audioRef.current.currentTime = elapsedTime;
      audioRef.current.play().then(() => {
        setIsPlaying(true);
        setOpenModal(false);
      }).catch(error => {
        // console.log.log("Play audio failed on button click:", error);
      });
    } else {
      // console.log.error('Invalid elapsed time on resume click:', elapsedTime);
    }
  };

  const handleCloseClick = () => {
    setOpenConfirmModal(true);
  };

  const handleConfirmClose = () => {
    setOpenConfirmModal(false);
    navigate(`/room/${roomCode}`);
  };

  const handleCancelClose = () => {
    setOpenConfirmModal(false);
  };

  const formatTime = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        height: '100vh',
        overflow: 'hidden',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100vw',
      }}
    >
      <audio ref={audioRef} controls={false} />
      <Box
        sx={{
          position: 'relative',
          width: '600px',
          height: '600px',
        }}
      >
        <Lottie
          loop
          animationData={pulseAnimation}
          play={isPlaying}
          speed={0.5}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Box
        sx={{
          position: 'absolute',
          bottom: '20px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'white',
            borderRadius: '12px',
            padding: '10px 20px',
            width: '22vw',
            textAlign: 'center',
            marginRight: '10px',
          }}
        >
          <Typography
            component="span"
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: 'primary.main',
            }}
          >
            {formatTime(remainingTime)}
          </Typography>
        </Box>
        <IconButton
          color="primary"
          onClick={handleCloseClick}
          sx={{
            backgroundColor: 'white',
            borderRadius: '50%',
            padding: '10px',
            '&:hover': {
              backgroundColor: 'white',
            },
          }}
        >
          <ExitToAppIcon />
        </IconButton>
      </Box>
      {/* Confirmation Modal */}
      <Modal
        open={openConfirmModal}
        onClose={handleCancelClose}
        aria-labelledby="confirm-close-modal-title"
        aria-describedby="confirm-close-modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', p: 4, borderRadius: '8px', textAlign: 'center' }}>
          <Typography id="confirm-close-modal-title" variant="h6" component="h2">
            {t('are_you_sure_to_quit')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '20px' }}>
            <Button onClick={handleConfirmClose} variant="contained" color="primary" sx={{ marginRight: 1 }}>
              {t('yes')}
            </Button>
            <Button onClick={handleCancelClose} variant="contained" color="grey">
              {t('no')}
            </Button>
          </Box>
        </Box>
      </Modal>
      {/* Resume Modal */}
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 300, bgcolor: 'background.paper', p: 4, borderRadius: '8px', textAlign: 'center' }}>
          <Typography id="modal-title" variant="h5">
            {t('still_here')}
          </Typography>
          <Button onClick={handleResumeClick} variant="contained" color="primary" sx={{ mt: 2 }}>
            {t('resume')}
          </Button>
        </Box>
      </Modal>
    </Box>
  );

};

export default AudioStreamer;