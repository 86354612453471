import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom'; 
import XP from '../components/session/XP';
import Badge from '../components/session/Badge';
import SoloStreak from '../components/session/SoloStreak';
import DuoStreak from '../components/session/DuoStreak';
import ReviewSlide from '../components/session/ReviewSlide';
import ManageDuoStreak from '../components/profile/friends/ManageDuoStreak';
import { Box, Button } from '@mui/material';
import { Device } from '@capacitor/device';
import ZenSpinner from '../components/ZenSpinner';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const SessionPage = () => {
  const { sessionId } = useParams();
  const [sessionData, setSessionData] = useState(null);
  const [error, setError] = useState(null);
  const [step, setStep] = useState(0);
  const [isIos, setIsIos] = useState(false);
  const { currentUser } = useAuth();
  const navigate = useNavigate(); 
  const location = useLocation();
  const [hasAvailableFriends, setHasAvailableFriends] = useState(false);
  const { t } = useTranslation(); // Hook pour les traductions

  // État pour la review
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');

  useEffect(() => {
    const checkPlatform = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setIsIos(true);
      }
    };

    checkPlatform();
  }, []);

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/meditation-sessions/sessions/${sessionId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });

        if (!response.ok) {
          throw new Error(`Erreur HTTP: ${response.status}`);
        }

        const data = await response.json();
        // console.log('📩 Données de la session récupérées:', data); 
        setSessionData(data);
      } catch (error) {
        setError(error.message);
      }
    };

    fetchSessionData();
}, [sessionId]);


  useEffect(() => {
    if (currentUser && currentUser.friends) {
      const checkAvailableFriends = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/duo-streaks/`, {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
          });
          const duoStreaks = await response.json();

          const availableFriends = currentUser.friends.filter(friend => {
            return !duoStreaks.some(streak =>
              (streak.user1Id === currentUser.userId && streak.user2Id === friend._id) ||
              (streak.user2Id === currentUser.userId && streak.user1Id === friend._id)
            );
          });

          setHasAvailableFriends(availableFriends.length > 0);
        } catch (error) {
          console.error('Erreur lors de la vérification des amis disponibles:', error);
        }
      };

      checkAvailableFriends();
    }
  }, [currentUser]);

  const handleNext = async () => {
    if (step === 1 && hasReviewSlide) {
  
      try {
        await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/reviews`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify({
            meditationId: sessionData.meditationId || null,
            techniqueId: sessionData.techniqueId || null,
            rating: rating || null,
            comment: comment.trim() || null
          })
        });
  
      } catch (error) {
        console.error('❌ Erreur lors de l’envoi de la review:', error);
      }
    }
  
    setStep(prevStep => Math.min(prevStep + 1, totalSlides - 1));
  };
  

  const handleFinish = () => {
    if (location.state?.fromRoom) {
      navigate(-2);
    } else {
      navigate(-1);
    }
  };

  if (!sessionData) {
    return <ZenSpinner />;
  }

  const hasUnlockedBadges = sessionData.badgesUnlocked && sessionData.badgesUnlocked.length > 0;
  const hasSoloStreak = sessionData.soloStreak.currentSoloStreak > 1;
  const hasDuoStreaks = sessionData.duoStreaks && sessionData.duoStreaks.length > 0;
  const isFriend = currentUser && currentUser.friends && currentUser.friends.length > 0;
  const hasReviewSlide = sessionData.meditationId || sessionData.techniqueId;

  const totalSlides = 1 + (hasUnlockedBadges ? 1 : 0) + (hasSoloStreak ? 1 : 0) + (hasDuoStreaks ? 1 : 0) + (hasAvailableFriends ? 1 : 0) + (hasReviewSlide ? 1 : 0);

  return (
    <Box sx={{ overflow: 'hidden', width: '100vw', height: '100vh' }}>
      <Box
        sx={{
          display: 'flex',
          transition: 'transform 0.5s ease',
          transform: `translateX(-${step * 100}vw)`,
          width: totalSlides * 100 + 'vw',
          height: '100%'
        }}
      >
        <Box sx={{ width: '100vw', height: '100%' }}>
          <XP
            onNext={handleNext}
            experiencePoints={sessionData.experiencePointsAdded || 0}
            meditationTime={sessionData.session.listenedDuration || 0}
          />
        </Box>

        {hasReviewSlide && (
          <Box sx={{ width: '100vw', height: '100%' }}>
            <ReviewSlide rating={rating} setRating={setRating} comment={comment} setComment={setComment} />
          </Box>
        )}

        {hasUnlockedBadges && (
          <Box sx={{ width: '100vw', height: '100%' }}>
            <Badge onNext={handleNext} newlyUnlockedBadges={sessionData.badgesUnlocked || []} />
          </Box>
        )}

        {hasSoloStreak && (
          <Box sx={{ width: '100vw', height: '100%' }}>
            <SoloStreak onNext={handleNext} currentStreak={sessionData.soloStreak.currentSoloStreak} />
          </Box>
        )}

        {hasDuoStreaks && (
          <Box sx={{ width: '100vw', height: '100%' }}>
            <DuoStreak 
              onNext={handleNext} 
              duoStreaks={sessionData.duoStreaks} 
              currentSoloStreak={sessionData.soloStreak.currentSoloStreak} 
            />
          </Box>
        )}

        {hasAvailableFriends && (
          <Box sx={{ width: '100vw', height: '100%' }}>
            <ManageDuoStreak />
          </Box>
        )}

      </Box>

      {step < totalSlides - 1 ? (
        <Box sx={{ position: 'fixed', bottom: isIos ? '32px' : '20px', width: '90%', left: '50%', transform: 'translateX(-50%)', display: 'flex', gap: 2 }}>
          <Button variant="contained" onClick={handleNext} disabled={step === totalSlides - 1}>
            {t('next')}
          </Button>
        </Box>
      ) : (
        <Box sx={{ position: 'fixed', bottom: isIos ? '32px' : '20px', width: '90%', left: '50%', transform: 'translateX(-50%)', display: 'flex' }}>
          <Button variant="contained" onClick={handleFinish}>
            {t('finish')}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default SessionPage;
