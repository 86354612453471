import React from 'react';
import { Box, Typography } from '@mui/material';
import { Share } from '@capacitor/share';
import ShareRoundedIcon from '@mui/icons-material/ShareRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const Invite = () => {
  const { t } = useTranslation(); // Hook pour les traductions

  const handleInvite = async () => {
    await Share.share({
      title: t('invite_title'),
      text: t('invite_text'),
      url: 'https://heyraoul.fr',
      dialogTitle: t('invite_dialog_title')
    });
  };

  return (
    <Box onClick={handleInvite} sx={{ bgcolor: 'secondary.main', py: 3, px: 3, borderRadius: '8px' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ textAlign: 'left' }}>
          <Typography variant="h6" sx={{ mb: 0, lineHeight: '1em', mb: 0.8 }}>
            {t('invite_friends')}
          </Typography>
          <Typography variant="body2" sx={{ mb: 0, lineHeight: '1em' }}>
            {t('send_free_access')}
          </Typography>
        </Box>
        <ShareRoundedIcon sx={{ fontSize: 30 }} />
      </Box>
    </Box>
  );
};

export default Invite;
