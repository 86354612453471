import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Box, Typography, CircularProgress, Button, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import LogoImage from '../../assets/logo.svg';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const VerifyAccountPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const [searchParams] = useSearchParams();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState(''); // Pour stocker l'email pour renvoyer le lien de vérification
  const [resendMessage, setResendMessage] = useState(''); // Message après la demande de renvoi
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get('token');
    if (token) {
      fetch(`${backendUrl}/api/users/verify?token=${token}`)
        .then(response => response.json())
        .then(data => {
          setLoading(false);
          setMessage(data.message);
        })
        .catch(error => {
          console.error('Error verifying account:', error);
          setLoading(false);
          setMessage(t('verification_error')); // Utilisation de la traduction
        });
    } else {
      setLoading(false);
      setMessage(t('no_token_provided')); // Utilisation de la traduction
    }
  }, [searchParams, t]);

  const handleResendVerificationEmail = () => {
    const language = localStorage.getItem('i18nextLng') || 'en'; // Récupérer la langue depuis le localStorage
    setLoading(true);
  
    fetch(`${backendUrl}/api/users/resend-verification-email`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, language }), // Inclure la langue
    })
      .then(response => response.json())
      .then(data => {
        setLoading(false);
        setResendMessage(data.message);
      })
      .catch(error => {
        console.error('Error resending verification email:', error);
        setLoading(false);
        setResendMessage(t('resend_error')); // Utilisation de la traduction
      });
  };
  
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      p: 4,
    }}>
      <img src={LogoImage} alt="Logo" style={{ height: '50px', margin: '0 auto', marginBottom: '2rem' }} />
      {loading ? (
        <CircularProgress />
      ) : (
        <>
          <Typography variant="h6" sx={{ mb: 0.5 }}>{message}</Typography>
          {message === t('email_verified_successfully') && (
            <>
              <Typography variant="body1" sx={{ mt: 2 }}>
                {t('now_you_can_login')}
              </Typography>
              <Button
                variant="contained"
                color="primary"
                sx={{ mt: 2 }}
                onClick={() => window.location.href = 'raoulapp://deep-link/login'}
              >
                {t('open_app')}
              </Button>
            </>
          )}
          {message !== t('email_verified_successfully') && (
            <Box sx={{ mt: 4 }}>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {t('if_not_received_email')}
              </Typography>
              <TextField
                fullWidth
                type="email"
                label={t('email')}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                margin="normal"
              />
              <Button
                variant="contained"
                color="primary"
                onClick={handleResendVerificationEmail}
                disabled={loading}
              >
                {t('resend_verification_link')}
              </Button>
              {resendMessage && (
                <Typography variant="body2" sx={{ mt: 2 }}>
                  {resendMessage}
                </Typography>
              )}
            </Box>
          )}
        </>
      )}
    </Box>
  );
};

export default VerifyAccountPage;
