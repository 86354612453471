import React, { useState, useEffect } from 'react';
import { Box, FormControl, InputLabel, MenuItem, Select, Switch, FormControlLabel, Typography } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MeditationNotificationSettings = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { currentUser, refreshUser } = useAuth();
  const [notificationsEnabled, setNotificationsEnabled] = useState(currentUser.meditationTime !== 'unset');
  const [meditationTime, setMeditationTime] = useState(currentUser.meditationTime !== 'unset' ? currentUser.meditationTime : 'morning');

  useEffect(() => {
    setNotificationsEnabled(currentUser.meditationTime !== 'unset');
    setMeditationTime(currentUser.meditationTime !== 'unset' ? currentUser.meditationTime : 'morning');
  }, [currentUser.meditationTime]);

  const handleSwitchChange = async (event) => {
    const enabled = event.target.checked;
    setNotificationsEnabled(enabled);

    const newMeditationTime = enabled ? meditationTime : 'unset';

    // Mise à jour dans la base de données
    await updateMeditationTime(newMeditationTime);
  };

  const handleTimeChange = async (event) => {
    const time = event.target.value;
    setMeditationTime(time);

    if (notificationsEnabled) {
      // Mise à jour dans la base de données uniquement si les notifications sont activées
      await updateMeditationTime(time);
    }
  };

  const updateMeditationTime = async (newMeditationTime) => {
    try {
      const response = await fetch(`${backendUrl}/api/users/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ meditationTime: newMeditationTime }),
      });

      if (!response.ok) {
        throw new Error(t('error_updating_notification_settings'));
      }

      await refreshUser();
    } catch (error) {
      console.error(t('error_updating_notification_settings'), error);
    }
  };

  return (
    <Box sx={{ mb: 3.5 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} >
        <Typography variant="h6">{t('daily_ritual')}</Typography>
        <FormControlLabel
          sx={{ mr: -1 }}
          control={
            <Switch
              checked={notificationsEnabled}
              onChange={handleSwitchChange}
              name="notificationsEnabled"
              color="primary"
            />
          }
        />
      </Box>
      <FormControl fullWidth sx={{ mt: 0 }} disabled={!notificationsEnabled}>
        <Select
          labelId="meditation-time-label"
          id="meditation-time-select"
          value={meditationTime}
          onChange={handleTimeChange}
        >
          <MenuItem value="morning">{t('morning')}</MenuItem>
          <MenuItem value="noon">{t('noon')}</MenuItem>
          <MenuItem value="evening">{t('evening')}</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default MeditationNotificationSettings;
