import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Gestion multilingue avec i18n
import MeditationCardComponent from './MeditationCardComponent';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const MeditationListComponent = () => {
  const { i18n } = useTranslation(); // Récupération de la langue active
  const navigate = useNavigate();
  const [meditations, setMeditations] = useState([]);
  const [loading, setLoading] = useState(true); // État de chargement

  useEffect(() => {
    // Chargement des méditations depuis le backend
    const fetchMeditations = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/meditations`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('La récupération des méditations a échoué');
        }
        const data = await response.json();
        setMeditations(data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false); // Indiquer que le chargement est terminé
      }
    };

    fetchMeditations();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/meditation/${id}`); // Redirection vers les détails de la méditation
  };

  if (loading) {
    // Affichage de Skeletons pendant le chargement
    return (
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
        {[1, 2, 3, 4].map((item) => (
          <Box key={item} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Skeleton variant="rectangular" height={140} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
      {meditations.map((meditation) => {
        // Gestion multilingue avec fallback sur 'fr'
        const title = meditation.title ? meditation.title[i18n.language] || meditation.title['fr'] : 'N/A';
        const description = meditation.description ? meditation.description[i18n.language] || meditation.description['fr'] : 'N/A';
        const audioUrl = meditation.audioUrl ? meditation.audioUrl[i18n.language] || meditation.audioUrl['fr'] : 'N/A';

        return (
          <MeditationCardComponent
            key={meditation._id}
            image={meditation.image || ''}
            title={title} // Titre traduit
            duration={meditation.duration} // Durée de la méditation
            description={description} // Description traduite
            audioUrl={audioUrl} // URL audio correspondant à la langue
            onClick={() => handleCardClick(meditation._id)} // Action au clic
          />
        );
      })}
    </Box>
  );
};

export default MeditationListComponent;
