import React, { useEffect, useState } from 'react';
import { Box, AppBar, Toolbar, IconButton, Avatar, Badge, Drawer } from '@mui/material';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import NavBarComponent from '../components/NavBarComponent';
import PersonSearchRoundedIcon from '@mui/icons-material/PersonSearchRounded';
import LogoImage from '../assets/logo.svg';
import AccessPage from './room/AccessPage';
import ProfilePage from './ProfilePage';
import FriendsPage from './profile/FriendsPage';
import { useSwipeable } from 'react-swipeable';
import useDeviceInfo from '../utils/useDeviceInfo';
import { registerForPushNotifications } from '../utils/PushNotificationService';

const TodayPage = () => {
  const { currentUser } = useAuth();
  const [isProfileDrawerOpen, setIsProfileDrawerOpen] = useState(false);
  const [isFriendsDrawerOpen, setIsFriendsDrawerOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { paddingTop, paddingBottom } = useDeviceInfo();
  
  useEffect(() => {
    // Vérifier si l'utilisateur est onboarded, sinon rediriger vers la page /first
    if (currentUser && typeof currentUser.onboarded !== 'undefined') {
      if (!currentUser.onboarded) {
        navigate('/first');
      } else {
        // Logique supplémentaire si l'utilisateur est onboarded, comme l'enregistrement des notifications push
        registerForPushNotifications();
      }
    }
  }, [currentUser, navigate]);

  useEffect(() => {
    if (location.state && location.state.openFriendsDrawer) {
      setIsFriendsDrawerOpen(true);
    }
  }, [location.state]);

  const handleProfileOpen = () => {
    setIsProfileDrawerOpen(true);
  };

  const handleProfileClose = () => {
    setIsProfileDrawerOpen(false);
  };

  const handleFriendsOpen = () => {
    setIsFriendsDrawerOpen(true);
  };

  const handleFriendsClose = () => {
    setIsFriendsDrawerOpen(false);
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (event) => {
      if (isFriendsDrawerOpen) {
        handleFriendsClose();
        event.preventDefault();
      }
    },
    onSwipedRight: (event) => {
      if (isProfileDrawerOpen) {
        handleProfileClose();
        event.preventDefault();
      }
    },
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  return (
    <Box sx={{ pt: paddingTop + 2 }} {...swipeHandlers}>
      <AppBar position="fixed" sx={{ backgroundColor: 'white', boxShadow: 'none', px: 1, pb: 1, display: 'flex', top: 0, pt: paddingTop + 1 }}>
        <Toolbar>
          <Box sx={{ width: '30%', display: 'flex', justifyContent: 'flex-start' }}>
            <IconButton color="inherit" onClick={handleFriendsOpen} sx={{ color: 'black' }}>
              <Badge badgeContent={1} color="error">
                <PersonSearchRoundedIcon sx={{ fontSize: 30 }} />
              </Badge>
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img src={LogoImage} alt="Logo" style={{ height: 40, marginTop: '-5px' }} />
          </Box>
          <Box sx={{ width: '30%', display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton color="inherit" onClick={handleProfileOpen} sx={{ color: 'black' }}>
              <Avatar sx={{ border: '1px solid #ddd' }} src={currentUser?.avatar || ''} />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      <Box sx={{
        display: 'flex',
        justifyContent: 'center',
        overflow: 'auto',
        flexDirection: 'column',
        px: 3,
        pt: 7,
        pb: 10
      }}>
        <AccessPage />
      </Box>

      <Drawer
        anchor="right"
        open={isProfileDrawerOpen}
        onClose={handleProfileClose}
        PaperProps={{ style: { width: '100%', height: '100%' } }}
      >
        <ProfilePage onClose={handleProfileClose} isOpen={isProfileDrawerOpen} />
      </Drawer>

      <Drawer
        anchor="left"
        open={isFriendsDrawerOpen}
        onClose={handleFriendsClose}
        PaperProps={{ style: { width: '100%', height: '100%' } }}
      >
        <FriendsPage onClose={handleFriendsClose} />
      </Drawer>

      <NavBarComponent />
    </Box>
  );
};

export default TodayPage;
