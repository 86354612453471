import React, { useState } from 'react';
import { Box, Drawer, Button, Typography, TextField, Dialog, FormControl, MenuItem, InputLabel, Select, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import useDeviceInfo from '../../utils/useDeviceInfo';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded'; // Importer l'icône de retour
import EditProfileDetails from './EditProfileDetails'; // Importer le composant de modification du profil
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded'; // Icône pour "Compte et profil"
import HelpRoundedIcon from '@mui/icons-material/HelpRounded'; // Icône pour "Aide"
import PolicyRoundedIcon from '@mui/icons-material/PolicyRounded'; // Icône pour "Politique de confidentialités"
import DescriptionRoundedIcon from '@mui/icons-material/DescriptionRounded'; // Icône pour "Conditions d'utilisation"
import TranslateRoundedIcon from '@mui/icons-material/TranslateRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook pour les traductions

const backendUrl = process.env.REACT_APP_BACKEND_URL;


const EditProfile = ({ isOpen, onClose, onUpdate }) => {
  const { logout } = useAuth();
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState('');
  const [isProfileDetailsOpen, setIsProfileDetailsOpen] = useState(false); // État pour gérer l'ouverture du drawer de modification du profil
  const { paddingTop } = useDeviceInfo();
  const { t, i18n } = useTranslation(); // Hook pour les traductions



  const handleLogout = () => {
    logout();
  };

  const handleDeleteAccount = async () => {
    if (deleteConfirmation !== 'aurevoir') {
      alert(t('delete_account_warning'));
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/api/users/delete`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la suppression du compte');
      }

      logout();
      onClose();
    } catch (error) {
      console.error('Erreur lors de la suppression du compte:', error.message);
      alert(t('delete_account_error'));
    }
  };

  const handleChange = async (event) => {
    const selectedLanguage = event.target.value;
    i18n.changeLanguage(selectedLanguage); // Change la langue dans i18n
    localStorage.setItem('i18nextLng', selectedLanguage); // Met à jour le localStorage
  
    try {
      const response = await fetch(`${backendUrl}/api/users/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ language: selectedLanguage }), // Mise à jour de la langue dans le modèle utilisateur
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de la langue');
      }
  
      const updatedUser = await response.json();
      // console.log('Langue mise à jour avec succès :', updatedUser.language);
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la langue :', error.message);
    }
  };
  

  return (
    <>
      {/* Drawer principal pour les paramètres */}
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: '100%', // Full width
            maxWidth: '100%', // S'assurer qu'il prend toute la largeur
          },
        }}
      >
        <Box sx={{ p: 3, pt: paddingTop + 3.5 }}>
          {/* Icône de retour et titre */}
          <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center', mb: 2 }}>
            <IconButton
              size="small"
              sx={{
                p: 0.5,
                border: '1px solid #ddd',
                borderRadius: '50%',
              }}
              aria-label="retour"
              onClick={onClose}
            >
              <ArrowBackIosRoundedIcon fontSize="small" />
            </IconButton>

            <Typography variant="h5" sx={{ m: 0 }} gutterBottom>
              {t('settings')}
            </Typography>
          </Box>

          {/* Menu des paramètres */}
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {/* Compte et profil */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderRadius: 1,
                '&:hover': { backgroundColor: 'action.hover' },
                cursor: 'pointer',
                borderBottom: '1px solid #DDD',
                py: 2.5,
              }}
              onClick={() => setIsProfileDetailsOpen(true)}
            >
              <AccountCircleRoundedIcon />
              <Typography variant="body1" fontWeight="bold">
                {t('profile_and_account')}
              </Typography>
            </Box>

            {/* Aide */}
            <Box
              component="a"
              href="https://www.heyraoul.fr/support"
              target="_blank"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderRadius: 1,
                '&:hover': { backgroundColor: 'action.hover' },
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'inherit',
                borderBottom: '1px solid #DDD',
                py: 2.5,
              }}
            >
              <HelpRoundedIcon />
              <Typography variant="body1" fontWeight="bold">
                {t('help')}
              </Typography>
            </Box>

            {/* Politique de confidentialités */}
            <Box
              component="a"
              href="https://www.heyraoul.fr/privacy"
              target="_blank"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderRadius: 1,
                '&:hover': { backgroundColor: 'action.hover' },
                cursor: 'pointer',
                textDecoration: 'none',
                color: 'inherit',
                borderBottom: '1px solid #DDD',
                py: 2.5,
              }}
            >
              <PolicyRoundedIcon />
              <Typography variant="body1" fontWeight="bold">
                {t('privacy_policy')}
              </Typography>
            </Box>

            {/* Conditions Générales d'Utilisation */}
            <Box
              component="a"
              href="https://www.heyraoul.fr/terms"
              target="_blank"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderRadius: 1,
                '&:hover': { backgroundColor: 'action.hover' },
                cursor: 'pointer',
                textDecoration: 'none',
                borderBottom: '1px solid #DDD',

                color: 'inherit',
                py: 2.5,
              }}
            >
              <DescriptionRoundedIcon />
              <Typography variant="body1" fontWeight="bold">
                {t('terms_of_use')}
              </Typography>
            </Box>

            {/* Changement de langue */}
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                borderRadius: 1,
                py: 2,
              }}
            >
              <Typography variant="body1" fontWeight="bold" sx={{ flexGrow: 1, display: "flex", gap: 1 }}>
                <TranslateRoundedIcon />
                {t('language')}
              </Typography>

              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <Select
                  value={i18n.language} // La langue actuelle
                  onChange={handleChange} // Gestion du changement de langue
                >
                  <MenuItem value="en">English</MenuItem>
                  <MenuItem value="fr">Français</MenuItem>
                </Select>
              </FormControl>
            </Box>

          </Box>

          {/* Boutons de déconnexion et suppression de compte */}
          <Button variant="contained" onClick={handleLogout} sx={{ mt: 0, width: '100%' }}>
            {t('logout')}
          </Button>
          <Button variant="text" color="error" onClick={() => setDeleteDialogOpen(true)} sx={{ mt: 1, width: '100%' }}>
            {t('delete_account')}
          </Button>
        </Box>
      </Drawer>

      {/* Drawer pour la modification du profil */}
      <EditProfileDetails
        isOpen={isProfileDetailsOpen}
        onClose={() => setIsProfileDetailsOpen(false)}
        onUpdate={onUpdate}
      />

      {/* Dialogue de suppression de compte */}
      <Dialog open={deleteDialogOpen} onClose={() => setDeleteDialogOpen(false)}>
        <DialogTitle>{t('delete_account_confirmation')}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {t('delete_account_instruction')}
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label={t('confirmation')}
            fullWidth
            value={deleteConfirmation}
            onChange={(e) => setDeleteConfirmation(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
            {t('cancel')}
          </Button>
          <Button onClick={handleDeleteAccount} color="error">
            {t('delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProfile;
