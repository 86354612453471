import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography, IconButton, Avatar, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button, Alert, Collapse } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const FriendsList = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { currentUser, removeFriend, refreshUser } = useAuth();
  const [friends, setFriends] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedFriend, setSelectedFriend] = useState(null);
  const [alertMessage, setAlertMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.friends) {
      setFriends(currentUser.friends);
    }
  }, [currentUser]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
      }, 3000); // Disparition après 3 secondes

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleClickOpen = (friend) => {
    setSelectedFriend(friend);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedFriend(null);
  };

  const handleRemove = async () => {
    if (selectedFriend) {
      try {
        await removeFriend(selectedFriend._id);
        await refreshUser();
        setAlertMessage(t('friend_removed'));
        setShowAlert(true);
        handleClose();
      } catch (error) {
        console.error('Erreur lors de la suppression de l\'ami:', error);
      }
    }
  };

  return (
    <Box sx={{ mb: 3 }}>
      <Collapse in={showAlert}>
        <Alert severity="success" onClose={() => setShowAlert(false)}>
          {alertMessage}
        </Alert>
      </Collapse>
      <List sx={{ p: 0 }}>
        {friends.length > 0 ? (
          friends.map((friend, index) => (
            <ListItem sx={{ p: 0 }} key={index}>
              <Avatar alt={friend.username} src={friend.avatar} sx={{ mr: 2, border: '1px solid #ddd' }} />
              <ListItemText primary={friend.firstName || 'Utilisateur'} secondary={`@${friend.username}`} />
              <IconButton 
                color="primary" 
                size='small'
                onClick={() => handleClickOpen(friend)} 
                sx={{ border: '1px solid #ddd', ml: 2 }}
              >
                <CloseIcon size='small' />
              </IconButton>
            </ListItem>
          ))
        ) : (
          <Typography variant='body2' align="center" sx={{ mt: 2, color: 'text.secondary' }}>
            {t('no_friends_yet')}
          </Typography>
        )}
      </List>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle sx={{ mt: 1 }} id="alert-dialog-title">{t('remove_friend')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('confirm_remove_friend', { friendName: selectedFriend?.firstName })}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ mb: 2, px: 2 }}>
          <Button onClick={handleClose} color="primary">
            {t('cancel')}
          </Button>
          <Button variant="outlined" onClick={handleRemove} color="error" autoFocus>
            {t('remove')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default FriendsList;
