import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { Box, IconButton, Typography, AppBar, Toolbar, Slider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CloseIcon from '@mui/icons-material/Close';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Lottie from 'react-lottie-player';
import pulseAnimation from '../assets/pulse.json';
import { useAuth } from '../contexts/AuthContext';
import useDeviceInfo from '../utils/useDeviceInfo';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AudioPlayerComponent = ({ audioSrc, title, onClose }) => {
  const { currentUser } = useAuth();
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [showAnimation, setShowAnimation] = useState(false);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const location = useLocation();

  // Extraire l'ID et le type depuis l'URL
  const pathParts = location.pathname.split('/');
  const type = pathParts[1]; // "meditation" ou "technique"
  const contentId = pathParts[2]; // L'ID de la méditation ou de la technique
  
  // Déterminer l'ID à envoyer
  const meditationId = type === 'meditation' ? contentId : null;
  const techniqueId = type === 'technique' ? contentId : null;
  
  const navigate = useNavigate();
  const audioRef = useRef(null);

  useEffect(() => {
    if (audioSrc) {
      const audio = new Audio(audioSrc);
      audioRef.current = audio;

      audio.addEventListener('loadeddata', () => {
        setDuration(audio.duration);
      });

      audio.addEventListener('timeupdate', () => {
        setCurrentTime(audio.currentTime);
        setProgress((audio.currentTime / audio.duration) * 100);
      });

      audio.addEventListener('ended', saveMeditationSession);

      return () => {
        audio.removeEventListener('loadeddata', () => {});
        audio.removeEventListener('timeupdate', () => {});
        audio.removeEventListener('ended', saveMeditationSession);
        audio.pause();
        audio.currentTime = 0;
      };
    }
  }, [audioSrc]);

  const togglePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
      setShowAnimation(false);
    } else {
      audioRef.current.play();
      setShowAnimation(true);
    }
    setIsPlaying(!isPlaying);
  };

  const handleSeek = (event, newValue) => {
    if (audioRef.current) {
      audioRef.current.currentTime = (newValue / 100) * duration;
      setCurrentTime(audioRef.current.currentTime);
      setProgress(newValue);
    }
  };

  const handleClose = () => {
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
    if (onClose) {
      onClose();
    }
  };

  const saveMeditationSession = async () => {
    if (currentUser && audioRef.current) {
      const listenedDuration = Math.round(audioRef.current.currentTime / 60);
  
      const sessionData = {
        userId: currentUser.userId,
        listenedDuration,
        sessionType: 'solo',
        meditationId,  // Utilisation de l'ID extrait de l'URL
        techniqueId,   // Si c'est une technique, l'ID sera rempli ici
      };
  
      // console.log("📤 Envoi de la session de méditation :", sessionData);
  
      try {
        const response = await fetch(`${backendUrl}/api/meditation-sessions/sessions`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          body: JSON.stringify(sessionData),
        });
  
        const result = await response.json();
        // console.log("📬 Réponse du backend :", result);
  
        if (!response.ok) throw new Error(result.message || 'Erreur lors de la sauvegarde de la session');
  
        const sessionId = result.session?._id;
        if (!sessionId) throw new Error('ID de session non trouvé');
  
        navigate(`/session/${sessionId}`);
      } catch (error) {
        console.error('❌ Erreur lors de la sauvegarde de la session de méditation :', error.message);
      }
    }
  };
  

  return (
    <Box
      sx={{
        width: '100vw',
        height: '100vh',
        bgcolor: 'secondary.main',
        position: 'fixed',
        top: 0,
        left: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #fff',
              backgroundColor: 'white',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="fermer"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ position: 'absolute', color: 'black', top: '20%', textAlign: 'center' }}>
        <Typography variant="h5" sx={{ mb: 1 }}>
          {title}
        </Typography>
      </Box>

      <IconButton
        onClick={togglePlayPause}
        sx={{
          zIndex: 1,
          color: isPlaying ? 'primary.main' : 'black',
          bgcolor: 'transparent',
          position: 'relative',
          borderRadius: '50%',
        }}
      >
        {isPlaying ? <PauseIcon fontSize="large" /> : <PlayArrowIcon fontSize="large" />}
      </IconButton>

      <Box
        sx={{
          position: 'absolute',
          width: '600px',
          height: '600px',
          opacity: showAnimation ? 1 : 0,
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <Lottie
          loop
          animationData={pulseAnimation}
          play={showAnimation}
          speed={0.5}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>

      <Typography
        variant="body"
        sx={{ mt: 3, color: 'black', position: 'absolute', bottom: 100 }}
      >
        {new Date(currentTime * 1000).toISOString().substr(14, 5)} - {new Date(duration * 1000).toISOString().substr(14, 5)}
      </Typography>

      <Box sx={{ position: 'absolute', bottom: 50, width: '50%', px: 2 }}>
        <Slider
          value={progress}
          onChange={handleSeek}
          aria-label="Audio Progress"
          sx={{
            height: 10,
            '& .MuiSlider-thumb': {
              height: 20,
              width: 20,
              backgroundColor: 'primary.main',
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default AudioPlayerComponent;
