import React, { useState } from 'react';
import { styled } from '@mui/system';
import { Box, Typography, Link, Alert, TextField, InputAdornment, Button, IconButton, AppBar, Toolbar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import AuthContainer from '../components/AuthContainer';
import { useAuth } from '../contexts/AuthContext';
import ForgotPasswordModal from '../components/profile/ForgotPasswordModal';
import { formatEmail } from '../utils/emailUtils';
import useDeviceInfo from '../utils/useDeviceInfo';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const AlertContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  width: '90%',
  left: '50%',
  transform: 'translateX(-50%)',
  transition: 'transform 0.5s ease-in-out, opacity 0.5s ease-in-out',
  opacity: 0,
  zIndex: 1000,
  '&.alert-visible': {
    transform: 'translateX(-50%) translateY(-20px)',
    opacity: 1,
  },
}));

const LoginPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { login, sendPasswordResetEmail } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleBackClick = () => {
    navigate('/');
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    try {
      const formattedEmail = formatEmail(email);
      await login(formattedEmail, password);
      window.location.href = '/today';
    } catch (error) {
      console.error(error);
      setErrorMessage(error.message || t('login_error'));
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
    }
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <>
      {/* Toolbar avec bouton de retour */}
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '94vh',
        overflow: 'hidden',
        p: 3,
      }}>
        <AuthContainer>
          <Typography variant="h4" sx={{ mb: 2, textAlign: 'center' }}>{t('welcome_back')}</Typography>
          <Box component="form" onSubmit={handleLogin} noValidate>
            <TextField
              fullWidth
              type="email"
              label={t('email')}
              name="email"
              sx={{ mb: 1.5 }}
            />
            <TextField
              fullWidth
              type={showPassword ? 'text' : 'password'}
              label={t('password')}
              name="password"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 2, mb: 1 }}
            >
              {t('login')}
            </Button>
            <Box mt={2} textAlign="center">
              <Link
                component="button"
                variant="body2"
                onClick={(event) => {
                  event.preventDefault(); // Empêche la soumission du formulaire
                  setModalOpen(true);     // Ouvre le modal
                }}
              >
                {t('forgot_password')}
              </Link>
            </Box>
          </Box>
        </AuthContainer>
      </Box>

      <AlertContainer sx={{ pb: paddingBottom , zIndex:10000 }} className={alertVisible ? 'alert-visible' : ''}>
        <Alert severity="error">{errorMessage}</Alert>
      </AlertContainer>

      <ForgotPasswordModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleSendResetLink={sendPasswordResetEmail}
      />
    </>
  );
};

export default LoginPage;
