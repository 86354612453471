import React, { useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Box, Typography, Link, styled, TextField, Button, MobileStepper, Alert, Grid, Fade, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useAuth } from '../contexts/AuthContext';
import { formatEmail } from '../utils/emailUtils';
import useDeviceInfo from '../utils/useDeviceInfo';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const AlertContainer = styled('div')(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: '50%',
  width: '90%',
  transform: 'translateX(-50%)',
  transition: 'transform 0.3s ease-in-out, opacity 0.3s ease-in-out',
  opacity: 0,
  zIndex: 1000,
  '&.alert-visible': {
    transform: 'translateX(-50%) translateY(-20px)',
    opacity: 1,
  },
}));

const avatars = [
  'https://data.heyraoul.fr/img/avatars/user/001.jpg',
  'https://data.heyraoul.fr/img/avatars/user/002.jpg',
  'https://data.heyraoul.fr/img/avatars/user/003.jpg',
  'https://data.heyraoul.fr/img/avatars/user/004.jpg',
  'https://data.heyraoul.fr/img/avatars/user/005.jpg',
  'https://data.heyraoul.fr/img/avatars/user/006.jpg',
];

const questions = [
  { question: "first_name_question", name: "firstName", type: "text" },
  { question: "last_name_question", name: "lastName", type: "text" },
  { question: "email_question", name: "email", type: "email" },
  { question: "phone_question", name: "phone", type: "tel" },
  { question: "avatar_question", name: "avatar", type: "avatar" },
  { question: "password_question", name: "password", type: "password" },
  { question: "confirm_password_question", name: "confirmPassword", type: "password" },
];

const SignUpPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { signUp } = useAuth();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [alertVisible, setAlertVisible] = useState(false);
  const [step, setStep] = useState(0);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    countryCode: '+33',  // Par défaut, France
    password: '',
    confirmPassword: '',
    birthDate: '',
    avatar: '',
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordHelperText, setPasswordHelperText] = useState('');

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>/\\[\]]/.test(password);
    const isValid = password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar;

    let helperText = '';
    if (!isValid) {
      helperText = t('password_criteria');
      if (password.length < minLength) helperText += t('password_min_length');
      if (!hasUpperCase) helperText += t('password_upper_case');
      if (!hasNumber) helperText += t('password_number');
      if (!hasSpecialChar) helperText += t('password_special_char');
    }

    setPasswordHelperText(helperText);
    return isValid;
  };

  const formatPhoneNumber = (phoneNumber) => {
    const phoneNumberObj = parsePhoneNumberFromString(`${formData.countryCode}${phoneNumber}`);
    return phoneNumberObj ? phoneNumberObj.number.replace(/\s+/g, '') : phoneNumber;
  };

  const validatePhoneNumber = (phoneNumber, countryCode) => {
    const fullNumber = `${countryCode}${phoneNumber}`;
    const phoneNumberObj = parsePhoneNumberFromString(fullNumber);

    if (phoneNumberObj && phoneNumberObj.isValid()) {
      return phoneNumberObj.number.replace(/\s+/g, ''); // Retourne le numéro sans espaces
    } else {
      return false; // Numéro invalide
    }
  };

  const handleCountryCodeChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      countryCode: e.target.value,
    }));
  };

  const handlePhoneChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      phone: e.target.value,
    }));
  };

  const validateStep = () => {
    let isValid = true;
    let errorMsg = '';

    if (step === 0 && !formData.firstName.trim()) {
      errorMsg = t('first_name_required');
      isValid = false;
    }

    if (step === 1 && !formData.lastName.trim()) {
      errorMsg = t('last_name_required');
      isValid = false;
    }

    if (step === 2) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(formData.email)) {
        errorMsg = t('invalid_email');
        isValid = false;
      }
    }

    if (step === 3) {
      const validPhone = validatePhoneNumber(formData.phone, formData.countryCode);
      if (!validPhone) {
        errorMsg = t('invalid_phone');
        isValid = false;
      } else {
        formData.phone = validPhone;
      }
    }

    if (step === 5 && !validatePassword(formData.password)) {
      errorMsg = t('invalid_password');
      isValid = false;
    }

    if (!isValid) {
      setErrorMessage(errorMsg);
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
    }

    return isValid;
  };

  const handleSignUp = async () => {
    if (!validatePassword(formData.password)) {
      setErrorMessage(t('password_criteria_error'));
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      setErrorMessage(t('password_mismatch'));
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
      return;
    }

    let phoneNumber = formData.phone.replace(/\s+/g, '');
    if (!phoneNumber.startsWith(formData.countryCode)) {
      phoneNumber = `${formData.countryCode}${phoneNumber}`;
    }

    try {
      const formattedEmail = formatEmail(formData.email);
      await signUp({ ...formData, email: formattedEmail, phone: phoneNumber });
      navigate('/waiting');
    } catch (error) {
      if (error.message.includes('E11000')) {
        setErrorMessage(t('email_already_used'));
      } else {
        setErrorMessage(t('signup_error'));
      }
      setAlertVisible(true);
      setTimeout(() => {
        setAlertVisible(false);
        setErrorMessage('');
      }, 4000);
    }
  };

  const handleBackClick = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate('/');
    }
  };

  const handleNextClick = () => {
    if (!validateStep()) {
      return;
    }

    if (step < questions.length - 1) {
      setStep(step + 1);
    } else {
      handleSignUp();
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'password') validatePassword(value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };

  const handleAvatarSelect = (avatar) => {
    setFormData({ ...formData, avatar: `${avatar}` });
  };

  return (
    <>
      <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', px: 6 }}>
        <Box sx={{ width: '100%', maxWidth: 400, minHeight: 150, position: 'relative' }}>
          {questions.map((question, index) => (
            <Fade
              key={index}
              in={step === index}
              timeout={{ enter: 800, exit: 800 }}
              mountOnEnter
              unmountOnExit
            >
              <Box textAlign="center" sx={{ position: 'absolute', top: -40, left: 0, right: 0 }}>
                <Typography variant="h5">{t(question.question)}</Typography>
                {question.name === 'avatar' ? (
                  <Grid container spacing={2} sx={{ mt: 1, justifyContent: 'center' }}>
                    {avatars.map((avatar) => (
                      <Grid item xs={3} key={avatar}>
                        <img
                          src={`${avatar}`}
                          alt={avatar}
                          style={{
                            width: '100%',
                            cursor: 'pointer',
                            borderRadius: '50%',
                            border: formData.avatar === `${avatar}` ? '6px solid #000' : '3px solid #000'
                          }}
                          onClick={() => handleAvatarSelect(avatar)}
                        />
                      </Grid>
                    ))}
                  </Grid>
                ) : question.name === 'phone' ? (
                  <Box sx={{ display: 'flex', mt: 1 }}>
                    <TextField
                      select
                      label={t('country_code')}
                      sx={{ mr: 1 }}
                      value={formData.countryCode}
                      onChange={handleCountryCodeChange}
                      margin="normal"
                      SelectProps={{
                        native: true,
                      }}
                    >
                      <option value="+33">+33 (FR)</option>
                      <option value="+1">+1 (US)</option>
                      <option value="+44">+44 (GB)</option>
                      <option value="+49">+49 (DE)</option>
                      <option value="+39">+39 (IT)</option>
                      <option value="+34">+34 (ES)</option>
                      <option value="+81">+81 (JP)</option>
                      <option value="+86">+86 (CN)</option>
                      <option value="+91">+91 (IN)</option>
                      <option value="+61">+61 (AU)</option>
                      <option value="+7">+7 (RU)</option>
                      <option value="+55">+55 (BR)</option>
                      <option value="+82">+82 (KR)</option>
                      <option value="+31">+31 (NL)</option>
                      <option value="+1">+1 (CA)</option>
                      <option value="+46">+46 (SE)</option>
                      <option value="+32">+32 (BE)</option>
                      <option value="+41">+41 (CH)</option>
                      <option value="+47">+47 (NO)</option>
                      <option value="+45">+45 (DK)</option>
                      <option value="+358">+358 (FI)</option>
                      <option value="+52">+52 (MX)</option>
                      <option value="+27">+27 (ZA)</option>
                    </TextField>
                    <TextField
                      fullWidth
                      label={t('phone_number')}
                      value={formData.phone}
                      onChange={handlePhoneChange}
                      margin="normal"
                      InputProps={{
                        startAdornment: <InputAdornment position="start">{formData.countryCode}</InputAdornment>,
                      }}
                    />
                  </Box>
                ) : (
                  <TextField
                    fullWidth
                    type={question.name === 'password' ? (showPassword ? 'text' : 'password') : (question.name === 'confirmPassword' ? (showConfirmPassword ? 'text' : 'password') : question.type)}
                    name={question.name}
                    value={formData[question.name]}
                    onChange={handleChange}
                    margin="normal"
                    InputProps={{
                      endAdornment: (question.name === 'password' || question.name === 'confirmPassword') && (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={question.name === 'password' ? handleClickShowPassword : handleClickShowConfirmPassword}
                          >
                            {(question.name === 'password' ? showPassword : showConfirmPassword) ? <VisibilityOff /> : <Visibility />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    helperText={question.name === 'password' && passwordHelperText ? passwordHelperText : ''}
                  />
                )}
              </Box>
            </Fade>
          ))}
        </Box>
        <Box sx={{ position: 'absolute', pb: paddingBottom, bottom: 0, width: '100%', bgcolor: 'background.paper' }}>
          <Box display="flex" sx={{ px: 2, mb: 2 }} justifyContent="space-between">
            <Button onClick={handleBackClick}>{t('back')}</Button>
            <Button variant="contained" onClick={handleNextClick}>{step < questions.length - 1 ? t('next') : t('sign_up')}</Button>
          </Box>
          <Box sx={{ width: '100%', mt: 2 }}>
            <Box sx={{ width: `${(step + 1) / questions.length * 100}%`, bgcolor: 'primary.main', height: 8, transition: 'width 0.5s' }} />
          </Box>
        </Box>
      </Box>
      <AlertContainer sx={{ pb: paddingBottom }} className={alertVisible ? 'alert-visible' : ''}>
        {errorMessage && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
      </AlertContainer>
    </>
  );
};

export default SignUpPage;
