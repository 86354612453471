import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, IconButton, Avatar, Drawer, AppBar, Toolbar, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TodayRoundedIcon from '@mui/icons-material/TodayRounded';
import MoreVertIcon from '@mui/icons-material/MoreVertRounded';
import Chat from '../../components/room/Chat';
import Countdown from '../../components/room/Countdown';
import EditGroup from '../../components/room/EditGroup';
import Reminder from '../../components/room/Reminder'; 
import MeditateWithFriend from '../../components/ads/MeditateWithFriend'; 
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useAuth } from '../../contexts/AuthContext';
import io from 'socket.io-client';
import { Device } from '@capacitor/device';
import useDeviceInfo from '../../utils/useDeviceInfo';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;
let socket;

const RoomPage = () => {
  const { roomCode } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook pour les traductions
  const [connectedUsers, setConnectedUsers] = useState([]);
  const [countdownActive, setCountdownActive] = useState(false);
  const [readyUsers, setReadyUsers] = useState([]);
  const { currentUser } = useAuth();
  const [isIos, setIsIos] = useState(false);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const [roomAccessGranted, setRoomAccessGranted] = useState(false);
  const [roomDetails, setRoomDetails] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // Drawer for EditGroup
  const [isReminderDrawerOpen, setIsReminderDrawerOpen] = useState(false); // Drawer for Reminder
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    const checkPlatform = async () => {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        setIsIos(true);
      }
    };

    checkPlatform();
  }, []);

  const fetchRoomDetails = async () => {
    try {
      const response = await fetch(`${backendUrl}/api/rooms`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      const data = await response.json();
      const room = data.rooms.find(room => room._id === roomCode);
      if (room) {
        setRoomAccessGranted(true);
        setRoomDetails(room); // Store room details
      } else {
        navigate('/');
      }
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'accès à la salle:', error);
      navigate('/unauthorized');
    }
  };

  useEffect(() => {
    if (currentUser) {
      fetchRoomDetails();
    }
  }, [currentUser, roomCode, navigate]);

  useEffect(() => {
    if (roomAccessGranted) {
      if (socket) {
        socket.disconnect();
        socket.off();
      }
      socket = io(backendUrl);
      joinRoom();

      socket.on('updateUserList', (users) => {
        setConnectedUsers(users);
      });

      socket.on('updateReadyUsers', (users) => {
        setReadyUsers(users);
      });

      socket.on('updateMeditationState', (state) => {
        switch (state) {
          case 'pending':
            navigate(`/room/${roomCode}`);
            break;
          case 'progress':
            navigate(`/room/${roomCode}`);
            break;
          case 'completed':
            navigate(`/room/${roomCode}`);
            break;
          default:
            break;
        }
      });

      socket.on('countdownStart', () => {
        setCountdownActive(true);
      });

      socket.on('cancelMeditation', () => {
        setCountdownActive(false);
        setReadyUsers([]);
      });

      return () => {
        socket.disconnect();
        socket.off();
      };
    }
  }, [roomCode, navigate, roomAccessGranted]);

  const joinRoom = () => {
    socket.emit('joinRoom', { room: roomCode, token: localStorage.getItem('token') });
  };

  const handleBackClick = () => {
    navigate('/');
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleOpenDrawer = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
    fetchRoomDetails(); // Actualiser les données de RoomPage après la fermeture du drawer
  };

  const handleReminderClick = () => {
    setIsReminderDrawerOpen(true);
  };

  const handleCloseReminderDrawer = () => {
    setIsReminderDrawerOpen(false);
  };

  const getFriendDetails = (userId) => {
    const friend = currentUser.friends.find(friend => friend._id === userId);
    return friend;
  };

  const getRoomDisplayName = (room) => {
    if (room.groupName) {
      return room.groupName;
    }

    const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
    const otherUser = getFriendDetails(otherUserId);

    return otherUser ? `${otherUser.firstName || 'Nom inconnu'} ${otherUser.lastName || ''}` : 'Utilisateur inconnu';
  };

  const getRoomAvatar = (room) => {
    if (room.groupAvatar) {
      return room.groupAvatar;
    }

    const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
    const otherUser = getFriendDetails(otherUserId);

    return otherUser ? otherUser.avatar : '/default-avatar.png';
  };

  const formatAvatarUrl = (url) => {
    // Assurez-vous que l'URL est correctement formatée pour être utilisée
    return url.startsWith('./') ? `${process.env.PUBLIC_URL}${url.slice(1)}` : url;
  };

  if (!roomAccessGranted) {
    return null;
  }

  return (
    <Box sx={{ maxWidth: 800, margin: 'auto' }}>
      {countdownActive ? (
        <Countdown roomCode={roomCode} onCancel={() => setCountdownActive(false)} onFinish={() => console.log('Meditation started')} />
      ) : (
        <>
          <Box sx={{ bgcolor: 'background.paper', borderBottom:'1px solid #DDD', display: 'flex', width:'100%', zIndex:'100', position:'fixed', top:0, alignItems: 'center', px: 2, pt: paddingTop + 2, pb:2 }}>
            <IconButton sx={{ p: 0 }} aria-label="retour" onClick={handleBackClick}>
              <ArrowBackIcon />
            </IconButton>
            <Avatar src={formatAvatarUrl(getRoomAvatar(roomDetails))} sx={{ width: 36, height: 36, ml: 1.5, border: '1px solid #DDD' }} />
            <Typography variant="body" fontWeight="bold" sx={{ flexGrow: 1, ml: 1 }}>
              {getRoomDisplayName(roomDetails)}
            </Typography>
            <IconButton aria-label="rappel" sx={{ p: 0, mr: 1 }} onClick={handleReminderClick}>
              <TodayRoundedIcon fontSize='medium' />
            </IconButton>
            <IconButton aria-label="options" sx={{ p: 0 }} onClick={handleOpenDrawer}>
              <MoreVertIcon fontSize='medium' />
            </IconButton>
          </Box>

          <Box>
            {currentUser && currentUser.subscriptionStatus === 'active' ? (
              <Chat roomCode={roomCode} />
            ) : (
              <Box sx={{ height:'100vh', display:'flex', alignItems:'center' }}>
                <MeditateWithFriend />
              </Box>
            )}
          </Box>

          <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
            <DialogTitle>{t('subscribers_only')}</DialogTitle>
            <DialogContent>
              <Typography>
                {t('subscribe_message')}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">{t('close')}</Button>
              <Button onClick={() => navigate('/subscription')} color="primary">{t('subscribe')}</Button>
            </DialogActions>
          </Dialog>

          {/* Drawer for EditGroup */}
          <Drawer
            anchor="right"
            open={isDrawerOpen}
            onClose={handleCloseDrawer}
            PaperProps={{ style: { width: '100%', height: '100%' } }}
          >
            <Box sx={{ px: 1, pt: paddingTop + 1 }}>
              <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent', color: 'inherit' }}>
                <Toolbar>
                  <IconButton sx={{ p: 0.5, border: '1px solid #ddd', borderRadius: '50%' }} edge="start" color="inherit" onClick={handleCloseDrawer} aria-label="close">
                    <ArrowBackIosRoundedIcon sx={{ color: 'grey' }} fontSize="small" />
                  </IconButton>
                  <Typography variant="h6" sx={{ textAlign: 'left', flex: 1, ml:1 }}>
                   {t('settings')}
                  </Typography>
                </Toolbar>
              </AppBar>
            </Box>
            <Box sx={{ px: 3 }}>
              <EditGroup roomId={roomCode} onClose={handleCloseDrawer} />
            </Box>
          </Drawer>

          {/* Drawer for Reminder */}
          <Drawer
            anchor="bottom"
            open={isReminderDrawerOpen}
            onClose={handleCloseReminderDrawer}
            PaperProps={{ style: { width: '100%'} }}  // Drawer de 40% de hauteur
          >
            <Box sx={{ p: 3 }}>
              <Reminder
                roomId={roomDetails?._id}
                userId={currentUser.userId}
                onClose={handleCloseReminderDrawer}
              />
            </Box>
          </Drawer>
        </>
      )}
    </Box>
  );
};

export default RoomPage;
