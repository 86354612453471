import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { Share } from '@capacitor/share';
import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const InviteFriends = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation(); // Hook pour les traductions

  const handleShare = async () => {
    try {
      await Share.share({
        title: t('share_title'),
        text: `${t('share_text')} ${currentUser?.username}`,
        url: 'https://heyraoul.fr',
        dialogTitle: t('share_dialog_title'),
      });
    } catch (error) {
      console.error('Erreur lors du partage:', error);
    }
  };

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: 'secondary.main',
        borderRadius: 2,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        cursor: 'pointer'
      }}
      onClick={handleShare}
    >
      <IconButton color="inherit" onClick={handleShare}>
        <ShareIcon />
      </IconButton>
      <Box sx={{ flex: 1, ml: 2 }}>
        <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 0 }} gutterBottom>
          {t('invite_friends')}
        </Typography>
        <Typography variant="body2">
          {t('username')}: <strong>{currentUser?.username}</strong>
        </Typography>
      </Box>
    </Box>
  );
};

export default InviteFriends;
