import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, CardContent, Typography, Button, Drawer, AppBar, Toolbar, IconButton } from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ZenSpinner from '../components/ZenSpinner';
import { useTheme } from '@mui/material/styles';
import AudioPlayerComponent from '../components/AudioPlayerComponent';
import DOMPurify from 'dompurify';
import useDeviceInfo from '../utils/useDeviceInfo';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TechniquePage = () => {
  const { techniqueId } = useParams();
  const [techniqueDetails, setTechniqueDetails] = useState(null);
  const navigate = useNavigate();
  const [showPlayer, setShowPlayer] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const theme = useTheme();
  const { t, i18n } = useTranslation(); // Hook pour les traductions

  useEffect(() => {
    const fetchTechniqueDetails = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/techniques/${techniqueId}`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails de la technique');
        }
        const data = await response.json();
        setTechniqueDetails(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTechniqueDetails();
  }, [techniqueId]);

  const handleBackClick = () => {
    navigate('/technique');
  };

  const handleStart = () => {
    setShowPlayer(true);
  };

  const handleMoreInfo = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  if (!techniqueDetails) {
    return <ZenSpinner />;
  }

  // Récupérer les traductions basées sur la langue active
  const title = techniqueDetails.title ? techniqueDetails.title[i18n.language] || techniqueDetails.title['fr'] : 'N/A';
  const description = techniqueDetails.description ? techniqueDetails.description[i18n.language] || techniqueDetails.description['fr'] : 'N/A';
  const audioUrl = techniqueDetails.audioUrl ? techniqueDetails.audioUrl[i18n.language] || techniqueDetails.audioUrl['fr'] : 'N/A';
  const facts = techniqueDetails.facts ? techniqueDetails.facts[i18n.language] || techniqueDetails.facts['fr'] : 'N/A';

  const cleanFactsHtml = DOMPurify.sanitize(facts || '');

  return (
    <Box sx={{ display: 'flex', height: '90vh', flexDirection: 'column' }}>
      {/* Barre d'outils avec bouton de retour */}
      <AppBar position="static" color="secondary" sx={{ border: '0px', boxShadow:'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            color="black"
            sx={{
              p: 0.5,
              backgroundColor: 'white',
              borderRadius: '50%',
              border:'1px solid #fff',
              mr: 2,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box sx={{ backgroundColor: 'secondary.main', display: 'flex' }}>
        {techniqueDetails.image ? (
          <img src={techniqueDetails.image} alt={title} style={{ borderRadius: '4px', width: '100%', flexShrink: 0 }} />
        ) : (
          <Box sx={{ borderRadius: '4px', width: '100%', height: 200, bgcolor: theme.palette.primary.main, flexShrink: 0 }} />
        )}
      </Box>

      <Box sx={{ flexGrow: 1, overflow: 'auto', pt: 0.5, pl: 3, pr: 3, mt: 2 }}>
        <CardContent sx={{ p: 0 }}>
          <Typography gutterBottom variant="h5" sx={{ mt: 1, mb: 0.6 }}>
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
              <AccessTimeFilledIcon sx={{ fontSize: 14 }} />
            </Box>
            <Box sx={{ paddingTop: '2px' }}>{`${techniqueDetails.duration} ${t('minutes')}`}</Box>
          </Typography>
          <Button variant="contained" startIcon={<PlayArrowIcon />} sx={{ width: '100%' }} onClick={handleStart}>
            <Box sx={{ paddingTop: '3px' }}>{t('start')}</Box>
          </Button>
          <Button
            variant="contained"
            startIcon={<InfoRoundedIcon />}
            sx={{
              width: '100%',
              mt: 1,
              backgroundColor: 'secondary.main',
              color: 'black',
              '&:hover': {
                backgroundColor: 'secondary.main',
              },
              '&:active': {
                backgroundColor: 'secondary.main',
              },
            }}
            disableRipple
            onClick={handleMoreInfo}
          >
            {t('details')}
          </Button>
        </CardContent>
      </Box>

      {showPlayer && (
        <AudioPlayerComponent
          audioSrc={audioUrl}
          title={title}
          contentType="technique"
          onClose={() => setShowPlayer(false)}
        />
      )}

      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleCloseDrawer}>
        <Box sx={{ maxHeight: '80vh', overflow: 'auto', p: 3 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {t('facts')}
          </Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: cleanFactsHtml }} />
        </Box>
      </Drawer>
    </Box>
  );
};

export default TechniquePage;
