import React, { useState, useEffect } from 'react';
import { Box, Drawer, Button, TextField, Typography, Grid, Alert } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import useDeviceInfo from '../../utils/useDeviceInfo'; // Importer le hook useDeviceInfo
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const avatars = [
  'https://data.heyraoul.fr/img/avatars/user/001.jpg',
  'https://data.heyraoul.fr/img/avatars/user/002.jpg',
  'https://data.heyraoul.fr/img/avatars/user/003.jpg',
  'https://data.heyraoul.fr/img/avatars/user/004.jpg',
  'https://data.heyraoul.fr/img/avatars/user/005.jpg',
  'https://data.heyraoul.fr/img/avatars/user/006.jpg',
];

const EditProfileDetails = ({ isOpen, onClose, onUpdate }) => {
  const { currentUser, refreshUser } = useAuth();
  const { t } = useTranslation(); // Hook pour les traductions
  const [profileData, setProfileData] = useState({
    firstName: currentUser?.firstName || '',
    lastName: currentUser?.lastName || '',
    email: currentUser?.email || '',
    avatar: currentUser?.avatar || '',
    password: '',
    confirmPassword: ''
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const { paddingTop } = useDeviceInfo(); // Utiliser le hook pour obtenir le paddingTop dynamique

  useEffect(() => {
    if (currentUser) {
      setProfileData({
        firstName: currentUser.firstName || '',
        lastName: currentUser.lastName || '',
        email: currentUser.email || '',
        avatar: currentUser.avatar || '',
        password: '',
        confirmPassword: ''
      });
    }
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfileData({ ...profileData, [name]: value });
  };

  const handleAvatarSelect = (avatar) => {
    setProfileData({ ...profileData, avatar: `${avatar}` });
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    return password.length >= minLength && hasUpperCase && hasNumber && hasSpecialChar;
  };

  const handleUpdateProfile = async () => {
    if (profileData.password && !validatePassword(profileData.password)) {
      setError(t('password_error'));
      return;
    }

    if (profileData.password !== profileData.confirmPassword) {
      setError(t('password_mismatch'));
      return;
    }

    try {
      const response = await fetch(`${backendUrl}/api/users/update`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(profileData),
      });

      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || t('update_error'));
      }

      await refreshUser();
      setSuccess(t('update_success'));
      setError('');
      onUpdate(); // Rafraîchir les données dans le composant parent
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error.message);
      setError(t('update_error'));
      setSuccess('');
    }
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box sx={{ width: 300, p: 3, pt: paddingTop + 3.5 }}>
        <Typography variant="h6" sx={{ mb: 2 }}>
          {t('edit_profile')}
        </Typography>

        <Grid container spacing={1} sx={{ mb: 2 }}>
          {avatars.map((avatar) => (
            <Grid item xs={4} key={avatar}>
              <img
                src={`${avatar}`}
                alt={avatar}
                style={{
                  width: '100%',
                  cursor: 'pointer',
                  borderRadius: '50%',
                  border: profileData.avatar === `${avatar}` ? '6px solid #000' : '3px solid #000'
                }}
                onClick={() => handleAvatarSelect(avatar)}
              />
            </Grid>
          ))}
        </Grid>

        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        {success && <Alert severity="success" sx={{ mb: 2 }}>{success}</Alert>}

        <TextField
          label={t('first_name')}
          name="firstName"
          value={profileData.firstName}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('last_name')}
          name="lastName"
          value={profileData.lastName}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('email')}
          name="email"
          value={profileData.email}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('new_password')}
          type="password"
          name="password"
          value={profileData.password}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label={t('confirm_password')}
          type="password"
          name="confirmPassword"
          value={profileData.confirmPassword}
          onChange={handleInputChange}
          fullWidth
          sx={{ mb: 2 }}
        />

        <Button variant="contained" color="primary" onClick={handleUpdateProfile} sx={{ mt: 0 }}>
          {t('save')}
        </Button>
      </Box>
    </Drawer>
  );
};

export default EditProfileDetails;
