import React from 'react';
import { Container, Typography, Box, Avatar, Button, TextField, IconButton } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import PeopleIcon from '@mui/icons-material/People';
import ChatIcon from '@mui/icons-material/Chat';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../../utils/useDeviceInfo';
import SelfImprovementRounded from '@mui/icons-material/SelfImprovementRounded';
import { useTranslation } from 'react-i18next'; // Hook pour les traductions

const MeditateWithFriend = () => {
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const { t } = useTranslation(); // Hook pour les traductions

  return (
    <Container sx={{ textAlign: 'center', px: 3, mt: 3 }}>

      <Box sx={{ bgcolor: 'secondary.main', borderTop: '1px solid #DDD', borderRight: '1px solid #DDD', borderLeft: '1px solid #DDD', borderRadius: '8px 8px 0 0', p: 1.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <LockIcon sx={{ mr: 1, fontSize: '20px' }} />
          <Typography fontWeight="bold" variant="h5">{t('reserved_for_subscribers')}</Typography>
        </Box>
      </Box>
      <Box sx={{ borderRight: '1px solid #DDD', borderLeft: '1px solid #DDD', p: 3 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <Avatar sx={{ bgcolor: '#EEE', color: 'black', mr: 1, width: 32, height: 32 }}>
            <PeopleIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography variant="body">
            {t('meditate_live_with_friends')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 1.5 }}>
          <Avatar sx={{ bgcolor: '#EEE', color: 'black', mr: 1, width: 32, height: 32 }}>
            <ChatIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography variant="body">
            {t('create_support_groups')}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar sx={{ bgcolor: '#EEE', color: 'black', mr: 1, width: 32, height: 32 }}>
            <CalendarTodayIcon sx={{ width: 16, height: 16 }} />
          </Avatar>
          <Typography variant="body">
            {t('schedule_daily_rituals')}
          </Typography>
        </Box>
      </Box>
      <Button variant="contained" sx={{ fontSize: '1.2em', borderRadius: '0 0 8px 8px' }} color="primary" onClick={() => navigate('/checkout')}>{t('try_for_free')}</Button>

      <Box sx={{ position: 'fixed', bottom: '-1px', left: 0, width: '100%', backgroundColor: 'white', pb: paddingBottom + 2, pt: 1.5 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mx: 2, backgroundColor: '#eee', borderRadius: '20px', padding: '4px', pl: '10px' }}>
          <TextField
            variant="outlined"
            placeholder={t('message_placeholder')}
            value={t('message')}
            disabled // Ajout du disabled ici pour désactiver l'input
            sx={{
              flexGrow: 1,
              '& .MuiOutlinedInput-root': {
                borderRadius: '20px',
                backgroundColor: '#eee',
                '& fieldset': {
                  border: 'none',
                },
                '&:hover fieldset': {
                  border: 'none',
                },
                '&.Mui-focused fieldset': {
                  border: 'none',
                },
              },
              '& .MuiOutlinedInput-input': {
                padding: '10px 12px',
              },
            }}
          />
          <IconButton
            sx={{
              marginLeft: '8px',
              color: 'black',
              backgroundColor: '#EEEEEE',
              '&:hover': {
                backgroundColor: '#DDDDDD',
              },
            }}
            disabled // Ajout du disabled ici pour désactiver le premier bouton
          >
          </IconButton>
          <IconButton
            sx={{
              marginLeft: '4px',
              color: 'white',
              backgroundColor: '#000',
              '&:hover': {
                backgroundColor: '#333',
              },
            }}
            disabled // Ajout du disabled ici pour désactiver le second bouton
          >
            <SelfImprovementRounded />
          </IconButton>
        </Box>
      </Box>

    </Container>
  );
};

export default MeditateWithFriend;
