import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Snackbar, Alert } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import Countdown from './Countdown';
import { useNavigate } from 'react-router-dom';
import MicRoundedIcon from '@mui/icons-material/MicRounded'; // Icône pour la méditation
import MusicNoteRounded from '@mui/icons-material/MusicNoteRounded'; // Icône pour le son avec arrière-plan
import VolumeOffIcon from '@mui/icons-material/VolumeOff'; // Icône pour le son désactivé
import AccessTimeIcon from '@mui/icons-material/AccessTime'; // Icône pour la durée
import ActiveUsers from './ActiveUsers'; // Composant ActiveUsers inclus
import useDeviceInfo from '../../utils/useDeviceInfo';
import { useSocket } from '../../contexts/SocketContext'; // Utilisation du SocketContext
import { useTranslation } from 'react-i18next'; // Hook pour les traductions

const InviteCard = ({ roomCode, activeUsers, onShow, onHide }) => {
  const { currentUser } = useAuth();
  const { t } = useTranslation(); // Hook pour les traductions
  const [readyUsers, setReadyUsers] = useState([]);
  const [isReady, setIsReady] = useState(false);
  const [showCountdown, setShowCountdown] = useState(false);
  const [meditationStarted, setMeditationStarted] = useState(false);
  const [meditationStartTime, setMeditationStartTime] = useState(0);
  const [meditationState, setMeditationState] = useState('none'); // Par défaut 'none'
  const [meditationType, setMeditationType] = useState('guided');
  const [soundBackground, setSoundBackground] = useState(true);
  const [duration, setDuration] = useState(5);
  const navigate = useNavigate();
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const socket = useSocket(); // Utilisation de SocketContext

  useEffect(() => {
    if (socket) {
      socket.emit('getMeditationState', { room: roomCode });

      socket.on('meditationStateResponse', (state) => {
        setMeditationState(state.state);
        setMeditationType(state.meditationType);
        setSoundBackground(state.soundBackground);
        setDuration(state.duration);
      });

      socket.on('meditationSettingsUpdated', (updatedSettings) => {
        setMeditationType(updatedSettings.meditationType);
        setSoundBackground(updatedSettings.soundBackground);
        setDuration(updatedSettings.duration);
      });

      socket.on('updateReadyUsers', (users) => {
        setReadyUsers(users.map(user => user.userId));
      });

      socket.on('startMeditation', (startTime) => {
        setMeditationStartTime(startTime);
        setShowCountdown(true);
      });

      socket.on('cancelMeditation', () => {
        setShowCountdown(false);
        setIsReady(false);
        setMeditationStarted(false);
      });

      socket.on('joinMeditationInProgress', (startTime) => {
        setMeditationStartTime(startTime);
        setMeditationStarted(true);
      });

      socket.on('updateMeditationState', (updatedState) => {
        setMeditationState(updatedState);
      });

      socket.on('error', (message) => {
        setAlertMessage(message);
        setAlertOpen(true);
      });
    }

    return () => {
      if (socket) {
        socket.off('meditationStateResponse');
        socket.off('meditationSettingsUpdated');
        socket.off('updateReadyUsers');
        socket.off('startMeditation');
        socket.off('cancelMeditation');
        socket.off('joinMeditationInProgress');
        socket.off('updateMeditationState');
        socket.off('error');
      }
    };
  }, [socket, roomCode]);

  useEffect(() => {
    if (meditationState === 'pending' || meditationState === 'progress' || meditationState === 'completed') {
      onShow();  // Lorsque l'InviteCard est affiché
    } else {
      onHide();  // Lorsque l'InviteCard est caché
    }
  }, [meditationState, onShow, onHide]);


  const handleReady = () => {
    const totalUsers = activeUsers.length;

    if (totalUsers < 2) {
      setAlertMessage(t('minimum_users'));
      setAlertOpen(true);
      return;
    }

    if (currentUser && currentUser.userId) {
      setIsReady(true);
      socket.emit('userReady', { room: roomCode, userId: currentUser.userId });
    } else {
      setAlertMessage(t('authentication_error'));
      setAlertOpen(true);
    }
  };

  const handleAlertClose = () => {
    setAlertOpen(false);
  };

  const handleCancel = () => {
    setShowCountdown(false);
    setIsReady(false);
    socket.emit('userNotReady', { room: roomCode, userId: currentUser.userId });
    socket.emit('cancelMeditation', { room: roomCode });
  };

  const handleJoinMeditation = () => {
    navigate(`/room/${roomCode}/live`);
  };

  return (
    <Box sx={{ mb:2 }} >

      {meditationState === 'none' && (
        null
      )}

      {meditationState === 'pending' && (
        <Box sx={{ textAlign: 'center', bgcolor: 'secondary.main', px:2, pt: 2, pb: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant='h6' fontWeight="bold" sx={{ mb: 0, textAlign: 'center' }}>{t('meditation_invite')}</Typography>

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', mb:0.5 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              <MicRoundedIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 0.4, textTransform: 'uppercase', fontWeight: 'bold', fontSize: '0.8em' }}>
                {meditationType === 'guided' ? t('guided') : t('free')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
              {soundBackground ? <MusicNoteRounded fontSize="small" /> : <VolumeOffIcon fontSize="small" />}
              <Typography variant="body2" sx={{ ml: 0.4, textTransform: 'uppercase', fontWeight: 'bold', fontSize: '0.8em' }}>
                {soundBackground ? t('music') : t('silence')}
              </Typography>
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <AccessTimeIcon fontSize="small" />
              <Typography variant="body2" sx={{ ml: 0.4, textTransform: 'uppercase', fontWeight: 'bold', fontSize: '0.8em' }}>
                {duration} {t('minutes')}
              </Typography>
            </Box>
          </Box>

          <ActiveUsers activeUsers={activeUsers} />

          <Button size="small" variant="contained" color="primary" sx={{ width: '40%', mx: 'auto' }} onClick={handleReady} disabled={isReady}>
            {t('ready')}
          </Button>
        </Box>
      )}

      {meditationState === 'progress' && (
        <Box sx={{ textAlign: 'center', bgcolor: 'secondary.main', px:2, pt: 2, pb: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant='h6' sx={{ textAlign: 'center' }}>{t('meditation_in_progress')}</Typography>
          <ActiveUsers activeUsers={activeUsers} />
          <Button size="small" variant="contained" color="primary" sx={{ width: 'fit-content', mx: 'auto' }} onClick={handleJoinMeditation}>
            {t('join')}
          </Button>
        </Box>
      )}

      {meditationState === 'completed' && (
        <Box sx={{ textAlign: 'center', bgcolor: 'secondary.main', px:2, pt: 2.5, pb: 2, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
          <Typography variant='h6'  sx={{ textAlign: 'center' }}>{t('meditation_completed')}</Typography>
          <ActiveUsers activeUsers={activeUsers} />
        </Box>
      )}

      <Snackbar sx={{ pb: paddingBottom }} open={alertOpen} autoHideDuration={6000} onClose={handleAlertClose}>
        <Alert onClose={handleAlertClose} severity="error" sx={{ width: '100%' }}>
          {alertMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default InviteCard;
