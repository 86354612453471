import React from 'react';
import { Box, Typography, CardMedia } from '@mui/material';
import useDeviceInfo from '../utils/useDeviceInfo';
import Feed from '../components/feed/Feed';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const FeedPage = () => {
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const { t } = useTranslation(); // Hook pour les traductions
  
  return (
    <Box sx={{ pb: paddingBottom + 10 }} > 

        {/* Ajout de l'image défilante en haut de la page */}
    <Box sx={{ pt: paddingTop + 2, pb: 1, height: '22vh', overflow: 'hidden', position: 'relative', backgroundColor: 'secondary.main' }}>
        <Box className="scrolling-image" sx={{ display: 'flex', height: '100%' }}>
            <CardMedia component="img" image="/img/allfriends.svg" alt={t('meditate_with_friends')} sx={{ height: '100%', display: 'block' }} />
        </Box>
    </Box>

    <Box sx={{ px: 3, mt: 2 }}>
        <Typography sx={{ mb: 1 }} variant="h6">{t('friends_news')}</Typography>
        <Feed />
    </Box>

    </Box>
  );
};

export default FeedPage;
