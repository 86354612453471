import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Button,
  Typography,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import { Share } from '@capacitor/share';
import { useAuth } from '../../contexts/AuthContext';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ManageInvitations = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { currentUser } = useAuth();
  const [state, setState] = useState({
    loading: true,
    guest: null,
    inviter: null,
    error: '',
  });
  const [success, setSuccess] = useState('');
  const [invitationLink, setInvitationLink] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ open: false, onConfirm: null, title: '', message: '' });
  const navigate = useNavigate();

  const handleStart = () => {
    navigate('/today');
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
        const language = localStorage.getItem('i18nextLng') || 'fr';
  
        const { subscriptionProductId } = currentUser;
  
        if (['classic', 'classic:classic'].includes(subscriptionProductId)) {
          const guestResponse = await fetch(`${backendUrl}/api/invitations/guest?language=${language}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          const guestData = await guestResponse.json();
  
          if (guestResponse.ok && guestData.guest) {
            setState((prev) => ({ ...prev, guest: guestData.guest, loading: false }));
          } else {
            setState((prev) => ({ ...prev, guest: null, loading: false }));
          }
        } else if (subscriptionProductId === 'guest') {
          const inviterResponse = await fetch(`${backendUrl}/api/invitations/inviter?language=${language}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
          const inviterData = await inviterResponse.json();
  
          if (inviterResponse.ok && inviterData.inviter) {
            setState((prev) => ({ ...prev, inviter: inviterData.inviter, loading: false }));
          } else {
            setState((prev) => ({ ...prev, inviter: null, loading: false }));
          }
        } else {
          setState((prev) => ({ ...prev, loading: false }));
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error.message);
        setState((prev) => ({
          ...prev,
          error: t('loading_error'),
          loading: false,
        }));
      }
    };
  
    fetchData();
  }, [currentUser.subscriptionProductId, t]);
  

  const handleCreateInvitation = async () => {
    try {
      const language = localStorage.getItem('i18nextLng') || 'fr';
      const response = await fetch(`${backendUrl}/api/invitations/invite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ inviterId: currentUser.userId, language }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || t('create_invitation_error'));
      }
  
      const link = `${window.location.origin}/accept-invitation/${data.invitation.token}`;
      setInvitationLink(link);
      setIsDialogOpen(true);
    } catch (error) {
      setState((prev) => ({ ...prev, error: t('invite_creation_failed') }));
    }
  };
  

  const handleRevokeAccess = () => {
    setConfirmationDialog({
      open: true,
      title: t('revoke_access_title'),
      message: t('revoke_access_message'),
      onConfirm: async () => {
        try {
          if (!state.guest || !state.guest.token) {
            throw new Error(t('no_invitation_to_revoke'));
          }
          const language = localStorage.getItem('i18nextLng') || 'fr';
          const response = await fetch(`${backendUrl}/api/invitations/revoke/${state.guest.token}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ language }),
          });
  
          if (!response.ok) {
            throw new Error(t('revoke_invitation_error'));
          }
  
          setSuccess(t('access_revoked'));
          setState((prev) => ({ ...prev, guest: null }));
          handleCloseConfirmationDialog();
        } catch (error) {
          setState((prev) => ({ ...prev, error: error.message || t('revoke_access_failed') }));
          handleCloseConfirmationDialog();
        }
      },
    });
  };
  
  const handleLeaveDuo = () => {
    setConfirmationDialog({
      open: true,
      title: t('leave_duo_title'),
      message: t('leave_duo_message'),
      onConfirm: async () => {
        try {
          const language = localStorage.getItem('i18nextLng') || 'fr';
          const response = await fetch(`${backendUrl}/api/invitations/leave`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ language }),
          });
  
          if (!response.ok) {
            throw new Error(t('leave_duo_error'));
          }
  
          setSuccess(t('duo_left'));
          setState((prev) => ({ ...prev, inviter: null }));
          handleCloseConfirmationDialog();
        } catch (error) {
          setState((prev) => ({ ...prev, error: error.message || t('leave_duo_failed') }));
          handleCloseConfirmationDialog();
        }
      },
    });
  };
  

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialog((prev) => ({ ...prev, open: false }));
  };

  if (state.loading) return <CircularProgress />;

  return (
    <>
      {state.error && (
        <Alert severity="error" onClose={() => setState((prev) => ({ ...prev, error: '' }))}>
          {state.error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" onClose={() => setSuccess('')}>
          {success}
        </Alert>
      )}
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 1, width:'100%' }}>

    {['classic', 'classic:classic'].includes(currentUser.subscriptionProductId) && (
  state.guest ? (
    <>
      <Typography variant="h4" sx={{ mb: 2, mt: 2, width: '70vw' }}>
        {t('duo_found')}
      </Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Avatar
            src={state.guest.avatar}
            alt={state.guest.firstName}
            sx={{ border: '1px solid #DDD', mr: 2 }}
          />
          <Typography sx={{ display: 'inline', mr: 0.5 }}>{state.guest.firstName}</Typography>
          <Typography sx={{ display: 'inline' }}>{state.guest.lastName}</Typography>
        </Box>
        <Button
          onClick={handleRevokeAccess}
          color="secondary"
          variant="contained"
          sx={{ ml: 2, width: 'fit-content', float: 'right' }}
        >
          {t('revoke')}
        </Button>
      </Box>

      <Button variant="contained" color="primary" sx={{ mt: 2 }} onClick={handleStart}>
        {t('start_meditating')}
      </Button>
    </>
  ) : (
    <>
      <Typography variant="h4" sx={{ mb: 1, mt: 0, width: '70vw' }}>
        {t('invite_friend')}
      </Typography>
      <Box
        onClick={handleCreateInvitation}
        sx={{
          background: 'black',
          borderRadius: 2,
          px: 2,
          py: 1.5,
          mt: 1,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          width: '100%',
        }}
      >
        <Box
          sx={{
            border: '2px dashed white',
            borderRadius: '50%',
            width: 52,
            height: 52,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'white',
            mr: 1.5,
          }}
        >
          <PersonAddAltRoundedIcon sx={{ color: 'white', fontSize: '1.8em' }} />
        </Box>
        <Typography
          sx={{
            fontWeight: 'bold',
            color: 'white',
            fontSize: '1.2em',
          }}
        >
          {t('add_duo')}
        </Typography>
      </Box>
      <Button variant="text" color="primary" sx={{ mt: 1 }} onClick={handleStart}>
        {t('later')}
      </Button>
    </>
  )
)}

      {currentUser.subscriptionProductId === 'guest' && state.inviter && (
        <>
        <Typography variant="h5" sx={{ mb: 2, mt:2, px:3 }}>
          {t('thanks_to_inviter')} {state.inviter.firstName} {t('enjoy_duo_subscription')}
        </Typography>
        <TableContainer component={Paper} elevation={0} sx={{ maxWidth: 500, borderRadius: '8px 8px 0 0', overflow: 'hidden', border: '1px solid #DDD' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="center"></TableCell>
                <TableCell align="center">{t('free')}</TableCell>
                <TableCell align="center">{t('duo')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="body2">{t('learn_basics')}</Typography>
                </TableCell>
                <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="body2">{t('meditate_live_with_friends')}</Typography>
                </TableCell>
                <TableCell align="center">–</TableCell>
                <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="body2">{t('create_support_circles')}</Typography>
                </TableCell>
                <TableCell align="center">–</TableCell>
                <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="left">
                  <Typography variant="body2">{t('unlock_all_meditations')}</Typography>
                </TableCell>
                <TableCell align="center">–</TableCell>
                <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Button variant="contained"  color="primary" sx={{ mt:2 }} onClick={handleStart}>
          {t('start_meditating')}
        </Button>

        </>
      )}

      <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
        <DialogTitle sx={{ pb:1 }} >{t('share_invitation_link')}</DialogTitle>
        <DialogContent sx={{ py:0 }} onClick={() => Share.share({
            title: t('invite_to_join_subscription'),
            text: t('join_my_subscription_with_this_link'),
            url: invitationLink,
          })}>
          <Typography sx={{ backgroundColor:'secondary.main', p:2, borderRadius:2 }} >{invitationLink}</Typography>
        </DialogContent>
        <DialogActions sx={{ p:2 }}>
          <Button variant='contained' onClick={() => Share.share({
            title: t('invite_to_join_subscription'),
            text: t('join_my_subscription_with_this_link'),
            url: invitationLink,
          })}>{t('share')}</Button>
          <Button variant="text" color="primary" sx={{ mt:1 }} onClick={handleStart}>
            {t('done')}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={confirmationDialog.open} onClose={handleCloseConfirmationDialog}>
        <DialogTitle>{confirmationDialog.title}</DialogTitle>
        <DialogContent sx={{ py:0 }} >
          <Typography>{confirmationDialog.message}</Typography>
        </DialogContent>
        <DialogActions sx={{ p:2 }}>
        <Button variant='contained' onClick={() => { confirmationDialog.onConfirm(); handleCloseConfirmationDialog(); }} color="primary">
            {t('confirm')}
          </Button>
          <Button onClick={handleCloseConfirmationDialog} >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </>
  );
};

export default ManageInvitations;
