import React, { useState } from 'react';
import { Box, Tabs, Tab, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import AddFriend from '../../components/profile/friends/AddFriend';
import AddContact from '../../components/profile/friends/AddContact';
import FriendsList from '../../components/profile/friends/FriendsList';
import ManageRequests from '../../components/profile/friends/ManageRequests';
import InviteFriends from '../../components/profile/friends/InviteFriends';
import { useAuth } from '../../contexts/AuthContext';
import useDeviceInfo from '../../utils/useDeviceInfo';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const FriendsPage = ({ onClose }) => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { refreshUser } = useAuth();
  const [tabIndex, setTabIndex] = useState(0);
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
    refreshUser();
  };

  return (
    <Box sx={{ px: 3, pt: paddingTop + 2, pb: paddingBottom + 2 }}>
      <AppBar position="static" elevation={0} sx={{ backgroundColor: 'transparent', color: 'inherit' }}>
        <Toolbar sx={{ p: 0, justifyContent:'space-between' }}>
          <Typography variant="h6">{t('my_friends')}</Typography>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
            }}
            aria-label="retour"
            onClick={onClose}
          >
            <ArrowForwardIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>
      <AddFriend />
      <InviteFriends />
      <Tabs 
        sx={{ mt:1 }}
        value={tabIndex} 
        onChange={handleChange} 
        aria-label="basic tabs example" 
        centered 
      >
        <Tab sx={{ width: '33%' }} label={t('suggestions')} />
        <Tab sx={{ width: '33%' }} label={t('requests')} />
        <Tab sx={{ width: '33%' }} label={t('connections')} />
      </Tabs>
      <Box sx={{ mt: 1 }}>
        {tabIndex === 0 && <AddContact />}
        {tabIndex === 1 && <ManageRequests />}
        {tabIndex === 2 && <FriendsList />}
      </Box>
    </Box>
  );
};

export default FriendsPage;
