import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, AppBar, Toolbar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import FixedHeightContainer from '../components/FixedHeightContainer';
import LogoImage from '../assets/logo.svg';
import useDeviceInfo from '../utils/useDeviceInfo';

// Import Lottie
import Lottie from 'react-lottie-player';
import cloudsAnimation from '../assets/cloud.json';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

// Styles
const CloudsContainer = styled(Box)({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100vw', // Toujours remplir la largeur de l'écran
  height: 'auto', // Ajuste automatiquement la hauteur
  minHeight: '100vh', // Assure qu'il couvre l'écran en hauteur si nécessaire
  overflow: 'hidden',
  zIndex: -1, // Les nuages restent en arrière-plan
  pointerEvents: 'none', // Les boutons restent cliquables
});

const HomePage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const [playAnimation, setPlayAnimation] = useState(true);
  const lottieRef = useRef(null);
  const { paddingTop, paddingBottom } = useDeviceInfo();

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.goToAndPlay(5 * 1000, true); // Commence l'animation à un moment donné
    }
  }, []);

  return (
    <FixedHeightContainer >
      <AppBar position="fixed" sx={{ backgroundColor: 'transparent', boxShadow: 'none', px: 1, py: 1, display: 'flex', pt: paddingTop + 2 }}>
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
            <img src={LogoImage} alt="Logo" style={{ height: 40, marginTop: '-5px' }} />
          </Box>
        </Toolbar>
      </AppBar>

      {/* Conteneur des nuages */}
      <CloudsContainer >
        <Lottie
          ref={lottieRef}
          loop
          animationData={cloudsAnimation}
          play={playAnimation}
          speed={0.1}
          style={{
            width: '100vw', // Pleine largeur de l'écran
            height: 'auto', // Ajuste la hauteur en conséquence
            minHeight: '100vh', // Garantit une hauteur minimale
          }}
        />
      </CloudsContainer>

      {/* Contenu principal */}
      <Box sx={{ px: 3, pt: 10 }}>
        <Typography variant="h3" align="center">
          {t('meditate_with')}
        </Typography>
        <Typography variant="h3" align="center">
          {t('friends')}
        </Typography>
        <Button variant="contained" color="primary" href="/signup" sx={{ mt: 2 }}>
          {t('get_started')}
        </Button>
        <Button variant="text" color="primary" href="/login" sx={{ mt: 0.5 }}>
          {t('login')}
        </Button>
      </Box>
    </FixedHeightContainer>
  );
};

export default HomePage;
