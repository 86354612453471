import React, { useEffect, useState } from 'react';
import { Box, List, ListItem, ListItemText, Typography, Avatar, Button, CardMedia } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import InviteFriends from '../../components/profile/friends/InviteFriends';
import useDeviceInfo from '../../utils/useDeviceInfo';
import ZenSpinner from '../../components/ZenSpinner';
import { useSocket } from '../../contexts/SocketContext'; // Import de SocketContext
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const RoomList = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { currentUser, refreshUser } = useAuth();
  const socket = useSocket(); // Utilisation du socket
  const [rooms, setRooms] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  useEffect(() => {
    if (socket && currentUser) {
      // Remplacer l'appel API par Socket.io
      socket.emit('fetchRooms', { token: localStorage.getItem('token') });
  
      socket.on('roomsList', (roomsData) => {
        // Trier les rooms par la date de la dernière méditation
        const sortedRooms = roomsData.sort((a, b) => {
          const dateA = new Date(getLastMeditationDate(a)).getTime();
          const dateB = new Date(getLastMeditationDate(b)).getTime();
          return dateB - dateA;
        });
  
        setRooms(sortedRooms);
        setLoading(false);
      });
  
      // Nettoyage du socket lors du démontage du composant
      return () => {
        socket.off('roomsList');
      };
    }
  }, [socket, currentUser]); // Assurez-vous que currentUser est prêt
  

  const handleJoinRoom = (roomCode) => {
    navigate(`/room/${roomCode}`);
  };

  const getFriendDetails = (userId) => {
    if (!currentUser || !currentUser.friends || !Array.isArray(currentUser.friends)) {
      // Si possible, actualisez `currentUser`
      if (refreshUser) {
        refreshUser(); // Appel à la fonction pour actualiser les données utilisateur
      }
      return null; // Retourner null pour éviter une erreur
    }
    return currentUser.friends.find(friend => friend._id === userId) || null;
  };
  

  const getRoomDisplayName = (room) => {
    if (room.groupName) {
      return room.groupName;
    }

    const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
    const otherUser = getFriendDetails(otherUserId);

    return otherUser ? `${otherUser.firstName || t('unknown_name')} ${otherUser.lastName || ''}` : t('unknown_user');
  };

  const getRoomAvatar = (room) => {
    if (room.groupAvatar) {
      return room.groupAvatar;
    }

    const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
    const otherUser = getFriendDetails(otherUserId);

    return otherUser ? otherUser.avatar : '/default-avatar.png';
  };

  const formatLastMeditationDate = (date) => {
    const today = new Date();
    const meditationDate = new Date(date);
  
    today.setHours(0, 0, 0, 0);
    meditationDate.setHours(0, 0, 0, 0);
  
    const diffTime = today - meditationDate;
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  
    if (diffDays === 0) {
      return t('online_today');
    } else if (diffDays === 1) {
      return t('online_yesterday');
    } else {
      return t('online_days', { days: diffDays });
    }
  };
  

  const getLastMeditationDate = (room) => {
    if (room.userIds.length === 2) {
      const otherUserId = room.userIds.find(userId => userId !== currentUser.userId);
      const otherUser = getFriendDetails(otherUserId);
      return otherUser && otherUser.lastMeditationDate
        ? formatLastMeditationDate(otherUser.lastMeditationDate)
        : t('not_meditated_yet');
    } else {
      let lastMeditationDate = null;

      room.userIds.forEach(userId => {
        const user = getFriendDetails(userId);
        if (user && user.lastMeditationDate) {
          const userDate = new Date(user.lastMeditationDate);
          if (!lastMeditationDate || userDate > lastMeditationDate) {
            lastMeditationDate = userDate;
          }
        }
      });

      return lastMeditationDate
        ? formatLastMeditationDate(lastMeditationDate)
        : t('no_recent_meditation');
    }
  };

  const handleCTAClick = () => {
    navigate('/today', { state: { openFriendsDrawer: true } });
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '84vh',
        }}
      >
        <ZenSpinner /> {/* Afficher le spinner de préchargement */}
      </Box>
    );
  }

  if (rooms.length === 0) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          height: '72vh',
          textAlign: 'center',
          p: 0,
        }}
      >
  
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
          <Box sx={{ pt: 3, pb:2, height: '20vh', overflow: 'hidden', width:'50%', position: 'relative', backgroundColor: 'secondary.main', borderRadius:2 }}>
              <Box className="scrolling-image" sx={{ display: 'flex', height: '100%' }}>
                  <CardMedia component="img" image="/img/allfriends.svg" alt="Méditer avec des amis" sx={{ height: '100%', display: 'block' }} />
              </Box>
          </Box>

          <Box sx={{ width:'100%' }} >
            <Typography sx={{ mt:1.5, px: 3.5 }}  variant='h5'>
              {t('add_friends_for')}
            </Typography>
            <Typography sx={{ mt:0, mb:1.5, px: 3.5 }}  variant='h5'>
               {t('meditate_with_them')}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={handleCTAClick}
              sx={{ mb:3.2, width:'50%' }}
            >
              {t('let_s_go')}
            </Button>
          </Box>

        </Box>

      </Box>
    );
  }

  return (
    <Box sx={{ mb: 3 }}>

      <InviteFriends />


      <Box sx={{ px:0, pt:2, pb: paddingBottom }}>
        <Typography variant="h6" sx={{ mb: 1}} gutterBottom>{t('meditate_with_friends')}</Typography>
        <List sx={{ p: 0 }}>
          {rooms.map((room, index) => (
            <ListItem sx={{ p: 0, mt:0.5 }} key={index} onClick={() => handleJoinRoom(room._id)}>
              <Avatar
                alt={getRoomDisplayName(room)}
                src={getRoomAvatar(room)}
                sx={{ mr: 2, border: '1px solid #ddd', width: 52, height: 52 }} // Augmentation de la taille de l'avatar
              />
              <ListItemText
                primary={getRoomDisplayName(room)}
                secondary={getLastMeditationDate(room)}
                sx={{
                  '& .MuiTypography-root': {
                    fontWeight: 'bold', 
                  },
                  '& .MuiListItemText-secondary': {
                    fontWeight: 'normal',
                    fontSize:'0.8em',
                  },
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
};

export default RoomList;
