import React, { useState, useEffect } from 'react';
import { Box, TextField, Button, Typography, Alert, Collapse } from '@mui/material';
import { useAuth } from '../../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const AddFriend = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { sendFriendRequest } = useAuth();
  const [friendIdentifier, setFriendIdentifier] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
        setError('');
        setSuccess('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const handleAddFriend = async () => {
    setError('');
    setSuccess('');
    setShowAlert(false);
  
    const response = await sendFriendRequest(friendIdentifier);
  
    if (response.success) {
      setSuccess(response.message);
      setFriendIdentifier('');
    } else {
      setError(response.message);
    }
  
    setShowAlert(true);
  };

  return (
    <Box sx={{ mb:1 }} >
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
        <TextField
          label={t('username')}
          variant="outlined"
          fullWidth
          value={friendIdentifier}
          onChange={(e) => setFriendIdentifier(e.target.value)}
          sx={{ mr: 1 }}
        />
        <Button sx={{ width: 'fit-content' }} variant="contained" color="primary" onClick={handleAddFriend}>
          {t('add')}
        </Button>
      </Box>
      <Box>
        <Collapse in={showAlert}>
          {error && <Alert severity="error" onClose={() => setShowAlert(false)}>{error}</Alert>}
          {success && <Alert severity="success" onClose={() => setShowAlert(false)}>{success}</Alert>}
        </Collapse>
      </Box>
    </Box>
  );
};

export default AddFriend;
