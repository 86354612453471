import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Device } from '@capacitor/device';
import { Purchases } from '@revenuecat/purchases-capacitor';
import { App } from '@capacitor/app';
import { Container, Button, Typography, CircularProgress, Box, Link, Drawer, IconButton, AppBar, Toolbar, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Close as CloseIcon, Check as CheckIcon } from '@mui/icons-material';
import { useAuth } from '../../contexts/AuthContext';
import PrivacyPolicyPage from '../PrivacyPolicyPage';
import useDeviceInfo from '../../utils/useDeviceInfo';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const entitlementIdentifier = process.env.REACT_APP_ENTITLEMENT_IDENTIFIER;

const CheckoutPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const [products, setProducts] = useState([]);
  const [purchasing, setPurchasing] = useState(false);
  const [isPrivacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [isPurchaseActive, setIsPurchaseActive] = useState(false); // Nouvel état pour suivre l'état de l'achat
  const navigate = useNavigate();
  const { currentUser, refreshUser } = useAuth();
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleBackClick = () => {
    navigate('/checkout');
  };

  useEffect(() => {
    const configurePurchases = async () => {
      if (!currentUser) {
        console.error('Current user is not defined');
        return;
      }

      try {
        const info = await Device.getInfo();
        const apiKey = info.platform === 'ios'
          ? process.env.REACT_APP_REVENUECAT_IOS_API_KEY
          : process.env.REACT_APP_REVENUECAT_ANDROID_API_KEY;

        if (!apiKey) {
          console.error('API key is missing for platform:', info.platform);
          return;
        }

        const appUserID = currentUser.userId;

        await Purchases.configure({ apiKey, appUserID });

        const offerings = await Purchases.getOfferings();
        setProducts(offerings.current.availablePackages);
      } catch (error) {
        console.error('Error configuring purchases:', error);
      }
    };

    configurePurchases();

    const handleAppStateChange = (state) => {
      console.log('App state change:', state);
      if (state.isActive && isPurchaseActive) {
        setIsPurchaseActive(false);
        navigate('/status');
      }
    };

    App.addListener('appStateChange', handleAppStateChange);

    return () => {
      App.removeListener('appStateChange', handleAppStateChange);
    };
  }, [currentUser, navigate, refreshUser, isPurchaseActive]);

  const handlePurchase = async (product) => {
    setPurchasing(true);
    try {
      setIsPurchaseActive(true); // Indiquer que l'achat est en cours
      const purchaseResult = await Purchases.purchasePackage({ aPackage: product });
      if (purchaseResult.customerInfo.entitlements.active[entitlementIdentifier]) {
        console.log('Achat réussi');
        await refreshUser();
        if (currentUser.subscriptionStatus === 'active') {
          navigate('/status');
        }
      }
    } catch (error) {
      if (error.code === Purchases.PURCHASES_ERROR_CODE.PURCHASE_CANCELLED_ERROR) {
        console.log('Achat annulé');
      } else {
        console.error('Erreur lors de l\'achat:', error);
      }
    } finally {
      setPurchasing(false);
      setIsPurchaseActive(false); // Réinitialiser l'état de l'achat
    }
  };

  const handlePrivacyPolicyOpen = () => {
    setPrivacyPolicyOpen(true);
  };

  const handlePrivacyPolicyClose = () => {
    setPrivacyPolicyOpen(false);
  };

  return (
    <>
      {/* Toolbar avec bouton de retour */}
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '94vh',
        textAlign: 'center',
        position: 'relative',
      }}
    >

      <Box component="img" src="/img/logo.svg" alt="Logo" width="28vw" sx={{ mb:3 }} />

      <Box>
        {!currentUser.subscriptionStartDate ? (
          <>
            <Box sx={{ mb: 4}} >
              <Typography variant="h4" sx={{ mb: 1.5, mt: 0 }} gutterBottom>
                {t('duo_subscription')}
              </Typography>
              <Typography variant="body" sx={{ fontWeight: 'bold', mb: 2, backgroundColor: 'secondary.main', px: 2, py: 1, borderRadius:2, width:'fit-content', margin:'0 auto 10px' }}>
                3.99€ {t('per_month')}
              </Typography>
            </Box>
            <Box sx={{ textAlign: 'left', mb: 3, px: 1 }}>

              {/* Tableau de comparaison des avantages */}
              <TableContainer component={Paper} elevation={0} sx={{ maxWidth: 500, borderRadius: '8px 8px 0 0', overflow: 'hidden', border: '1px solid #DDD' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">{t('free')}</TableCell>
                      <TableCell align="center">{t('duo')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('learn_basics')}</Typography>
                      </TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('meditate_with_friends')}</Typography>
                      </TableCell>
                      <TableCell align="center">–</TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('create_support_groups')}</Typography>
                      </TableCell>
                      <TableCell align="center">–</TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('unlock_all_meditations')}</Typography>
                      </TableCell>
                      <TableCell align="center">–</TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box variant="body" sx={{ fontWeight: 'bold', textAlign:'center', mt:'-2px', mb: 1, backgroundColor: 'secondary.main', px: 2, py: 1, borderRadius: '0 0 8px 8px', width:'100%' }}>
                1 {t('subscription')} = 2 {t('accesses')}
              </Box>

              <Button
                variant="contained"
                sx={{ mt: 2.5,mb: 2, fontSize: '1.2em' }}
                color="primary"
                fullWidth
                onClick={() => handlePurchase(products[0])}
                disabled={purchasing}
              >
                {purchasing ? <CircularProgress size={24} /> : t('start_trial')}
              </Button>
              <Typography variant="body2" sx={{ textAlign: 'center', fontStyle:'italic', fontSize: '12px', width:'80%', margin:'0 auto' }}>
                {t('auto_renewable')}
              </Typography>
              <Box sx={{ mt: 1.5, textAlign:'center', display:'flex', flexDirection:'row', justifyContent:'center' }}>
                <Link
                  href="https://www.apple.com/legal/internet-services/itunes/dev/stdeula/"
                  target="_blank"
                  rel="noopener"
                  sx={{ fontSize: '12px', textDecoration: 'underline', px:0.5, mb:1 }}
                >
                  {t('terms_of_service')}
                </Link>
              </Box>
            </Box>
          </>
        ) : (
          <>
            <Typography variant="h5" sx={{ mb: 2, mt: 0 }} gutterBottom>
              {t('welcome_back')}
            </Typography>

              {/* Tableau de comparaison des avantages */}
              <TableContainer component={Paper} elevation={0} sx={{ maxWidth: 500, borderRadius: '8px 8px 0 0', overflow: 'hidden', border: '1px solid #DDD' }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center"></TableCell>
                      <TableCell align="center">{t('free')}</TableCell>
                      <TableCell align="center">{t('duo')}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('learn_basics')}</Typography>
                      </TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('meditate_with_friends')}</Typography>
                      </TableCell>
                      <TableCell align="center">–</TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('create_support_groups')}</Typography>
                      </TableCell>
                      <TableCell align="center">–</TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell align="left">
                        <Typography variant="body2">{t('unlock_all_meditations')}</Typography>
                      </TableCell>
                      <TableCell align="center">–</TableCell>
                      <TableCell align="center"><CheckIcon sx={{ color: 'primary.main' }} /></TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>

              <Box variant="body" sx={{ fontWeight: 'bold', textAlign:'center', mt:'-2px', mb: 1, backgroundColor: 'secondary.main', px: 2, py: 1, borderRadius: '0 0 8px 8px', width:'100%' }}>
                1 {t('subscription')} = 2 {t('accesses')}
              </Box>

            <Button
              variant="contained"
              sx={{ mt: 1.5, fontSize: '1.2em' }}
              color="primary"
              fullWidth
              onClick={() => handlePurchase(products[0])}
              disabled={purchasing}
            >
              {purchasing ? <CircularProgress size={24} /> : t('renew_subscription')}
            </Button>
            <Typography variant="body2" sx={{ mt: 2, textAlign: 'center' }}>
              {t('auto_renewable')}
            </Typography>
          </>
        )}
      </Box>
      <Drawer
        anchor="bottom"
        open={isPrivacyPolicyOpen}
        onClose={handlePrivacyPolicyClose}
      >
        <Box sx={{ width: '100%', p: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', height: '50px' }}>
            <IconButton onClick={handlePrivacyPolicyClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={{ px: 3}}>
            <PrivacyPolicyPage />
          </Box>
        </Box>
      </Drawer>
    </Container>
    </>
  );
};

export default CheckoutPage;
