import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Avatar,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
} from '@mui/material';
import { Share } from '@capacitor/share';
import { useAuth } from '../../contexts/AuthContext';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ManageInvitations = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { currentUser } = useAuth();
  const [state, setState] = useState({
    loading: true,
    guest: null,
    inviter: null,
    error: '',
  });
  const [success, setSuccess] = useState('');
  const [invitationLink, setInvitationLink] = useState('');
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [confirmationDialog, setConfirmationDialog] = useState({ open: false, onConfirm: null, title: '', message: '' });

  useEffect(() => {
    const fetchData = async () => {
      try {
        setState((prev) => ({ ...prev, loading: true }));
        const language = localStorage.getItem('i18nextLng') || 'fr';
  
        const { subscriptionProductId } = currentUser;
  
        if (['classic', 'classic:classic'].includes(subscriptionProductId)) {
          const guestResponse = await fetch(`${backendUrl}/api/invitations/guest?language=${language}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
  
          if (guestResponse.ok) {
            const guestData = await guestResponse.json();
            if (guestData.guest) {
              setState((prev) => ({ ...prev, guest: guestData.guest, loading: false }));
            } else {
              setState((prev) => ({ ...prev, guest: null, loading: false }));
            }
          } else if (guestResponse.status === 404) {
            console.warn('Aucun guest trouvé (404).');
            setState((prev) => ({ ...prev, guest: null, loading: false }));
          } else {
            throw new Error('Erreur inconnue lors de la récupération du guest.');
          }
        } else if (subscriptionProductId === 'guest') {
          const inviterResponse = await fetch(`${backendUrl}/api/invitations/inviter?language=${language}`, {
            method: 'GET',
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
          });
  
          if (inviterResponse.ok) {
            const inviterData = await inviterResponse.json();
            if (inviterData.inviter) {
              setState((prev) => ({ ...prev, inviter: inviterData.inviter, loading: false }));
            } else {
              setState((prev) => ({ ...prev, inviter: null, loading: false }));
            }
          } else if (inviterResponse.status === 404) {
            console.warn('Aucun inviter trouvé (404).');
            setState((prev) => ({ ...prev, inviter: null, loading: false }));
          } else {
            throw new Error('Erreur inconnue lors de la récupération de l’inviter.');
          }
        } else {
          setState((prev) => ({ ...prev, loading: false }));
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error.message);
        setState((prev) => ({
          ...prev,
          error: t('error_loading_data'),
          loading: false,
        }));
      }
    };
  
    fetchData();
  }, [currentUser.subscriptionProductId, t]);
  
  const handleCreateInvitation = async () => {
    try {
      const language = localStorage.getItem('i18nextLng') || 'fr';
      const response = await fetch(`${backendUrl}/api/invitations/invite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ inviterId: currentUser.userId, language }),
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || t('error_creating_invitation'));
      }
  
      const link = `${window.location.origin}/accept-invitation/${data.invitation.token}`;
      setInvitationLink(link);
      setIsDialogOpen(true);
    } catch (error) {
      setState((prev) => ({ ...prev, error: t('unable_create_invitation') }));
    }
  };
  
  const handleRevokeAccess = () => {
    setConfirmationDialog({
      open: true,
      title: t('change_duo'),
      message: t('revoke_access_message'),
      onConfirm: async () => {
        try {
          if (!state.guest || !state.guest.token) {
            throw new Error(t('no_invitation_to_revoke'));
          }
          const language = localStorage.getItem('i18nextLng') || 'fr';
          const response = await fetch(`${backendUrl}/api/invitations/revoke/${state.guest.token}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ language }),
          });
  
          if (!response.ok) {
            throw new Error(t('error_revoking_invitation'));
          }
  
          setSuccess(t('duo_subscription_revoked'));
          setState((prev) => ({ ...prev, guest: null }));
          handleCloseConfirmationDialog();
        } catch (error) {
          setState((prev) => ({ ...prev, error: error.message || t('unable_revoke_invitation') }));
          handleCloseConfirmationDialog();
        }
      },
    });
  };
  
  const handleLeaveDuo = () => {
    setConfirmationDialog({
      open: true,
      title: t('leave_duo'),
      message: t('leave_duo_message'),
      onConfirm: async () => {
        try {
          const language = localStorage.getItem('i18nextLng') || 'fr';
          const response = await fetch(`${backendUrl}/api/invitations/leave`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ language }),
          });
  
          if (!response.ok) {
            throw new Error(t('error_leaving_duo'));
          }
  
          setSuccess(t('success_left_duo'));
          setState((prev) => ({ ...prev, inviter: null }));
          handleCloseConfirmationDialog();
        } catch (error) {
          setState((prev) => ({ ...prev, error: error.message || t('unable_leave_duo') }));
          handleCloseConfirmationDialog();
        }
      },
    });
  };
  

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  const handleCloseConfirmationDialog = () => {
    setConfirmationDialog((prev) => ({ ...prev, open: false }));
  };

  if (state.loading) return <CircularProgress />;

  return (
    <>
      {state.error && (
        <Alert severity="error" onClose={() => setState((prev) => ({ ...prev, error: '' }))}>
          {state.error}
        </Alert>
      )}
      {success && (
        <Alert severity="success" onClose={() => setSuccess('')}>
          {success}
        </Alert>
      )}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', mt: 1, border: '1px solid #DDD', borderRadius: 2, p: 1, width: '100%' }}>

        {['classic', 'classic:classic'].includes(currentUser.subscriptionProductId) && (
          state.guest ? (
            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Avatar src={state.guest.avatar} alt={state.guest.firstName} sx={{ border: '1px solid #DDD', mr: 2 }} />
                <Typography sx={{ display: 'inline', mr: 0.5 }}>{state.guest.firstName}</Typography>
                <Typography sx={{ display: 'inline' }}>{state.guest.lastName}</Typography>
              </Box>
              <Button
                onClick={handleRevokeAccess}
                color="secondary"
                variant="contained"
                sx={{ ml: 2, width: 'fit-content', float: 'right' }}
              >
                {t('revoke')}
              </Button>
            </Box>
          ) : (
            <Box
              onClick={handleCreateInvitation}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                cursor: 'pointer',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  border: '2px dashed black',
                  borderRadius: '50%',
                  width: 42,
                  height: 42,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  color: 'grey',
                  mr: 1.5,
                }}
              >
                <PersonAddAltRoundedIcon sx={{ color: 'black' }} />
              </Box>
              <Typography sx={{ fontWeight: 'bold' }}>{t('add_duo')}</Typography>
            </Box>
          )
        )}

        {currentUser.subscriptionProductId === 'guest' && state.inviter && (
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Avatar src={state.inviter.avatar} alt={state.inviter.firstName} sx={{ border: '1px solid #DDD', mr: 2 }} />
              <Typography sx={{ display: 'inline', mr: 0.5 }}>{state.inviter.firstName}</Typography>
              <Typography sx={{ display: 'inline' }}>{state.inviter.lastName}</Typography>
            </Box>
            <Button onClick={handleLeaveDuo} color="secondary" variant="contained" sx={{ ml: 2, width: 'fit-content', float: 'right' }}>
              {t('leave_duo')}
            </Button>
          </Box>
        )}

        <Dialog open={isDialogOpen} onClose={handleCloseDialog}>
          <DialogTitle sx={{ pb: 1 }}>{t('share_invitation_link')}</DialogTitle>
          <DialogContent sx={{ py: 0 }} onClick={() => Share.share({
            title: t('invitation_to_join_subscription'),
            text: t('join_subscription_text'),
            url: invitationLink,
          })}>
            <Typography sx={{ backgroundColor: 'secondary.main', p: 2, borderRadius: 2 }}>{invitationLink}</Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button variant="contained" onClick={() => Share.share({
              title: t('invitation_to_join_subscription'),
              text: t('join_subscription_text'),
              url: invitationLink,
            })}>{t('share')}</Button>
          </DialogActions>
        </Dialog>

        <Dialog open={confirmationDialog.open} onClose={handleCloseConfirmationDialog}>
          <DialogTitle>{confirmationDialog.title}</DialogTitle>
          <DialogContent sx={{ py: 0 }}>
            <Typography>{confirmationDialog.message}</Typography>
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Button variant="contained" onClick={() => { confirmationDialog.onConfirm(); handleCloseConfirmationDialog(); }} color="primary">
              {t('confirm')}
            </Button>
            <Button onClick={handleCloseConfirmationDialog}>
              {t('cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default ManageInvitations;
