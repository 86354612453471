import React, { useState, useEffect } from 'react';
import {
  Button, Card, Typography, Grid, Box, IconButton, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const ReviewManagementComponent = () => {
  const [reviews, setReviews] = useState([]);
  const [aggregatedRatings, setAggregatedRatings] = useState({});
  const [filterType, setFilterType] = useState('all'); // 'all', 'meditation', 'technique'
  const [selectedId, setSelectedId] = useState('all'); // ID spécifique d'une méditation ou technique

  useEffect(() => {
    fetchReviews();
  }, []);

  const fetchReviews = async () => {
    const token = localStorage.getItem('token');
    const response = await fetch(`${backendUrl}/api/reviews`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (response.ok) {
      const { reviews, aggregatedRatings } = await response.json();
      setReviews(reviews);
      setAggregatedRatings(aggregatedRatings);
    } else {
      console.error('Erreur lors de la récupération des avis');
    }
  };

  const handleDelete = async (reviewId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cet avis ?')) {
      const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/api/reviews/${reviewId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.ok) {
        setReviews(reviews.filter(review => review._id !== reviewId));
      } else {
        console.error('Erreur lors de la suppression de l\'avis');
      }
    }
  };

  const filteredReviews = (reviews || []).filter(review => {
    if (filterType === 'meditation') return review.meditation;
    if (filterType === 'technique') return review.technique;
    return true;
  }).filter(review => {
    if (selectedId === 'all') return true;
    return review.meditation?._id === selectedId || review.technique?._id === selectedId;
  });

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>Gestion des avis</Typography>

      {/* Section : Moyennes des notes */}
      <Box sx={{ mb: 3, border:'1px solid #DDD', borderRadius:2, px:3, pt:2, pb:2.5, mb:2 }}>
        <Typography mb={1} variant="h6">Moyennes des avis</Typography>
        {Object.entries(aggregatedRatings).map(([key, data]) => (
            <Box sx={{ display:"flex", gap:1, alignItems:'center' }} >
                <Typography key={key} variant="body1">
                {data.title && data.title !== "Sans titre" ? data.title : "Méditation/Technique inconnue"} : ⭐ {data.average}/5 
                </Typography>
                <Typography variant='body2' >({data.count} avis)</Typography>
            </Box>
        ))}
    </Box>

      {/* Filtrage */}
      <Box sx={{ display: 'flex', flexDirection:"column", gap: 1, mb: 2 }}>
        <FormControl sx={{ minWidth: 200 }}>
          <Select value={filterType} onChange={(e) => setFilterType(e.target.value)}>
            <MenuItem value="all">Tous</MenuItem>
            <MenuItem value="meditation">Méditations</MenuItem>
            <MenuItem value="technique">Techniques</MenuItem>
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 300 }}>
        <Select value={selectedId} onChange={(e) => setSelectedId(e.target.value)}>
            <MenuItem value="all">Tous</MenuItem>
            {[...new Set(reviews.map(review => review.meditation?._id || review.technique?._id))].map(id => {
            const review = reviews.find(r => r.meditation?._id === id || r.technique?._id === id);
            const title = review.meditation?.title?.fr || review.meditation?.title?.en ||
                            review.technique?.title?.fr || review.technique?.title?.en || "Sans titre";
            return (
                <MenuItem key={id} value={id}>
                {title}
                </MenuItem>
            );
            })}
        </Select>
        </FormControl>

      </Box>

      {/* Liste des avis */}
      <Grid container spacing={2}>
        {filteredReviews.map((review) => (
          <Grid item xs={12} key={review._id}>
            <Card elevation={0} sx={{ p: 2, display: 'flex', justifyContent: 'space-between', alignItems: 'center', border:'1px solid #DDD', borderRadius:2 }}>
              <Box>
                <Typography variant="h6">
                  {review.meditation?.title?.fr || review.meditation?.title?.en || 
                   review.technique?.title?.fr || review.technique?.title?.en || 
                   "Sans titre"}
                </Typography>
                <Typography variant="body2">Utilisateur : {review.user.firstName} {review.user.lastName}</Typography>
                <Typography variant="body2">Note : {review.rating ? `${review.rating}/5` : "Aucune note"}</Typography>
                <Typography variant="body2">Commentaire : {review.comment || "Aucun commentaire"}</Typography>
              </Box>
              <IconButton onClick={() => handleDelete(review._id)} size="small">
                <DeleteIcon />
              </IconButton>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ReviewManagementComponent;
