import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Box, Typography, IconButton, AppBar, Toolbar } from '@mui/material';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import TechniqueListComponent from '../components/TechniqueListComponent';
import useDeviceInfo from '../utils/useDeviceInfo';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const TechniqueListPage = () => {
  const navigate = useNavigate();
  const { paddingTop, paddingBottom } = useDeviceInfo();
  const { t } = useTranslation(); // Hook pour les traductions

  const handleBackClick = () => {
    navigate('/temple');
  };

  return (
    <Box sx={{ pt: paddingTop + 1 }} >

      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ px: 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 1,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
          <Typography color="black" sx={{ lineHeight: '1em' }} variant="h5">
            {t('basic_techniques')}
          </Typography>
        </Toolbar>
      </AppBar>
    
      <Container sx={{ px: 3 }}>
        <Box sx={{ pb: 2 }}>
          <TechniqueListComponent />
        </Box>
      </Container>
    </Box>
  );
};

export default TechniqueListPage;
