import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import Backend from "i18next-http-backend"

const simplifyLanguage = (lng) => {
  return lng.split("-")[0]
}

const getInitialLanguage = () => {
  const storedLang = localStorage.getItem("i18nextLng")
  if (storedLang && ["fr", "en"].includes(storedLang)) {
    return storedLang
  }
  return "fr" // Default to French if no valid stored language
}

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    lng: getInitialLanguage(),
    fallbackLng: "fr",
    supportedLngs: ["fr", "en"],
    debug: true,
    ns: ["common"],
    defaultNS: "common",
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: "/locales/{{lng}}/{{ns}}.json",
    },
    detection: {
      order: ["localStorage"],
      lookupLocalStorage: "i18nextLng",
      caches: ["localStorage"],
    },
  })

// Function to change language manually
export const changeLanguage = (lng) => {
  const simplifiedLng = simplifyLanguage(lng)
  if (["fr", "en"].includes(simplifiedLng) && simplifiedLng !== i18n.language) {
    i18n.changeLanguage(simplifiedLng)
    localStorage.setItem("i18nextLng", simplifiedLng)
    console.log(`Language changed to: ${simplifiedLng}`)
  }
}

// Log when language is loaded
i18n.on("languageChanged", (lng) => {
  console.log(`Language loaded: ${lng}`)
})

export default i18n

