import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const CancelPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook pour les traductions

  const handleRetry = () => {
    navigate('/checkout');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh', textAlign: 'center', padding: 3 }}>
      <Typography variant="h4" sx={{ mb: 2 }}>
        {t('subscription_cancelled')}
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        {t('subscription_cancelled_message')}
      </Typography>
      <Button variant="contained" color="primary" onClick={handleRetry}>
        {t('retry_payment')}
      </Button>
    </Box>
  );
};

export default CancelPage;
