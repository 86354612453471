import React from 'react';
import { Box, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const Advices = () => {
  const { t } = useTranslation(); // Hook pour les traductions

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h4" gutterBottom>
        {t('meditation_advices_title')}
      </Typography>

      <Typography variant="body1" paragraph>
        {t('meditation_advices_intro')}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        1. {t('find_quiet_place')}
      </Typography>
      <Typography variant="body2" paragraph>
        {t('find_quiet_place_description')}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        2. {t('start_slowly')}
      </Typography>
      <Typography variant="body2" paragraph>
        {t('start_slowly_description')}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        3. {t('focus_on_breath')}
      </Typography>
      <Typography variant="body2" paragraph>
        {t('focus_on_breath_description')}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        4. {t('welcome_thoughts')}
      </Typography>
      <Typography variant="body2" paragraph>
        {t('welcome_thoughts_description')}
      </Typography>

      <Divider sx={{ my: 2 }} />

      <Typography variant="h5" gutterBottom>
        6. {t('explore_techniques')}
      </Typography>
      <Typography variant="body2" paragraph>
        {t('explore_techniques_description')}
      </Typography>
    </Box>
  );
};

export default Advices;
