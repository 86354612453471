import React from 'react';
import { Box, Typography, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Rating, { IconContainerProps } from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import ReviewsRoundedIcon from '@mui/icons-material/ReviewsRounded';
import { styled } from '@mui/material/styles';

// StyledRating avec agrandissement des icônes
const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-icon': {
    fontSize: '3rem', // Doubler la taille des icônes
  },
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

// Icônes personnalisées pour chaque valeur
const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon sx={{ fontSize: '3rem' }} color="error" />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ fontSize: '3rem' }} color="error" />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ fontSize: '3rem' }} color="warning" />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon sx={{ fontSize: '3rem' }} color="success" />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon sx={{ fontSize: '3rem' }} color="success" />,
    label: 'Very Satisfied',
  },
};

// Conteneur d'icônes personnalisé
function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

// Composant principal ReviewSlide
const ReviewSlide = ({ rating, setRating, comment, setComment }) => {
  const { t } = useTranslation();

  return (
    <Box 
      sx={{ 
        width: '100vw', 
        height: '100vh', 
        display: 'flex', 
        flexDirection: 'column', 
        alignItems: 'center', 
        justifyContent: 'center', 
        backgroundColor: 'secondary.main', 
        px: 3 
      }}
    >
      <ReviewsRoundedIcon sx={{ fontSize: "3em", mb: 1, ml: 1 }} />
      <Typography variant="h4" textAlign="center" mb={2}>
        {t('how_was_your_meditation')}
      </Typography>

      {/* Rating personnalisé avec icônes agrandies */}
      <StyledRating
        name="meditation-rating"
        value={rating}
        onChange={(event, newValue) => setRating(newValue)}
        IconContainerComponent={IconContainer}
        getLabelText={(value) => customIcons[value].label}
        highlightSelectedOnly
        size="large"
      />

      {/* Commentaire */}
      <TextField
        label={t('leave_a_comment')}
        multiline
        rows={3}
        fullWidth
        sx={{ mt: 3 }}
        value={comment}
        onChange={(e) => setComment(e.target.value)}
      />
    </Box>
  );
};

export default ReviewSlide;
