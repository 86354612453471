import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Icon } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import io from 'socket.io-client';
import { useTranslation } from 'react-i18next'; // Hook pour les traductions

const backendUrl = process.env.REACT_APP_BACKEND_URL;

let socket;

const Countdown = ({ onCancel, onFinish, roomCode }) => {
  const [seconds, setSeconds] = useState(3);
  const navigate = useNavigate();
  const { t } = useTranslation(); // Hook pour les traductions

  useEffect(() => {
    if (seconds === 0) {
      onFinish();
      socket.emit('startMeditation', { room: roomCode });
      navigate(`/room/${roomCode}/live`);
      return;
    }
    const interval = setInterval(() => {
      setSeconds(prev => prev - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, [seconds, onFinish, navigate, roomCode]);

  useEffect(() => {
    socket = io(backendUrl);
    return () => {
      socket.disconnect();
      socket.off();
    };
  }, []);

  const handleCancel = () => {
    socket.emit('cancelMeditation', { room: roomCode });
    onCancel();
    navigate(`/room/${roomCode}`);
  };

  return (
    <Box sx={{ textAlign: 'center', bgcolor: '#EEF8FA', height: '100vh', px: 2, pb:1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
      <Typography variant="h1">{seconds}</Typography>
      <Button size='small' startIcon={<CloseIcon />} onClick={handleCancel} variant="contained" sx={{ width:'fit-content', margin: '0 auto', mt:1, padding:'0 10px' }}>
        {t('cancel')}
      </Button>
    </Box>
  );
};

export default Countdown;
