import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { Box, Typography, Link, Button } from '@mui/material';
import LogoImage from '../assets/logo.svg'; // Assurez-vous que le chemin est correct
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const WaitingPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/login');
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100vh',
      textAlign: 'center',
      p: 4,
    }}>
      <img src={LogoImage} alt="Logo" style={{ height: '50px', margin: '0 auto', marginBottom: '2rem' }} />
      <Typography variant="h6" sx={{ mb: 1 }}>{t('validation_link_sent')}</Typography>
      <Button variant="contained" color="primary" onClick={handleBackClick} sx={{ mt: 0.5 }}>
        {t('login')}
      </Button>
      <Box mt={2}>
        <Typography variant="body2">
          {t('problem_contact_us')}{' '}
          <Link href="mailto:cool@heyraoul.fr">{t('contact_us')}</Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default WaitingPage;
