import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, Drawer } from '@mui/material';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import DOMPurify from 'dompurify'; // Pour sécuriser le contenu HTML
import quoteImage from '../../assets/img/005.jpg';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const QuoteCard = () => {
  const { t, i18n } = useTranslation(); // Hook pour gérer les traductions
  const [quote, setQuote] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false); // État pour gérer le drawer

  useEffect(() => {
    const storedQuote = localStorage.getItem('quote');
    const storedDate = localStorage.getItem('quoteDate');
    const today = new Date().toLocaleDateString();

    if (storedQuote && storedDate === today) {
      setQuote(JSON.parse(storedQuote));
      setIsLoading(false);
    } else {
      const fetchQuotes = async () => {
        try {
          const response = await fetch(`${backendUrl}/api/quotes`);
          const data = await response.json();
          const randomIndex = Math.floor(Math.random() * data.length);
          const selectedQuote = data[randomIndex];
          setQuote(selectedQuote);
          localStorage.setItem('quote', JSON.stringify(selectedQuote));
          localStorage.setItem('quoteDate', today);
        } catch (error) {
          console.error('Erreur lors de la récupération des citations :', error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchQuotes();
    }
  }, []);

  if (isLoading) {
    return <Typography variant="body1">{t('loading')}</Typography>;
  }

  const handleMoreInfo = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  // Obtenir les données dans la langue active
  const currentLang = i18n.language; // Langue active (ex. 'fr' ou 'en')
  const quoteContent = quote.content[currentLang] || quote.content.fr; // Fallback en français
  const quoteSource = quote.source?.[currentLang] || quote.source?.fr; // Fallback en français
  const quoteTitle = quote.title?.[currentLang] || quote.title?.fr; // Fallback en français
  const quoteAuthor = quote.author?.[currentLang] || quote.author?.fr; // Fallback en français

  const cleanSourceHtml = DOMPurify.sanitize(quoteSource || '');

  return (
    <>
      <Paper
        onClick={handleMoreInfo}
        elevation={0}
        sx={{
          display: 'flex',
          mb: 2,
          pt: 2,
          pl: 2,
          pr: 2,
          alignItems: 'center',
          borderRadius: '8px',
          border: '1px solid',
          borderColor: 'grey.300',
        }}
      >
        <Box sx={{ flexShrink: 0, display: 'flex' }}>
          <img
            src={quoteImage}
            alt="Quote"
            style={{ width: 90, height: 'auto', borderRadius: '8px', marginRight: '20px' }}
          />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <Typography align="left" variant="body1">
            {quoteContent}
          </Typography>
          {quote.source && (
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1, mb: 1, cursor: 'pointer' }}>
              <InfoRoundedIcon fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                {t('details')}
              </Typography>
            </Box>
          )}
        </Box>
      </Paper>

      {/* Drawer pour afficher les détails de la citation */}
      <Drawer anchor="bottom" open={isDrawerOpen} onClose={handleCloseDrawer}>
        <Box p={3} sx={{ maxHeight: '80vh', overflow: 'auto', pt: 4 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {quoteTitle || t('quote')}
          </Typography>
          <Typography variant="body1" dangerouslySetInnerHTML={{ __html: cleanSourceHtml }} />
          <Typography variant="body2" sx={{ mb: 1, mt: 3, fontWeight: 'bold' }}>
            {t('source')}: {quoteAuthor || t('unknown')}
          </Typography>
        </Box>
      </Drawer>
    </>
  );
};

export default QuoteCard;
