import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, Button, CircularProgress, TextField, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material'; // Import des icônes
import { Device } from '@capacitor/device';
import { useAuth } from '../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const AcceptInvitationPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { token } = useParams();
  const { currentUser, login } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [inviterName, setInviterName] = useState('');
  const [isBrowser, setIsBrowser] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    const checkPlatform = async () => {
      const info = await Device.getInfo();
      setIsBrowser(info.platform === 'web'); // Si plateforme = web, c'est un navigateur
    };

    checkPlatform();

    const fetchInviter = async () => {
      setLoading(true);
      try {
        const language = localStorage.getItem('i18nextLng') || 'fr';
        const response = await fetch(`${backendUrl}/api/invitations/inviter/${token}?language=${language}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
    
        const data = await response.json();
        if (!response.ok) {
          throw new Error(data.error || t('inviter_info_error'));
        }
    
        setInviterName(`${data.inviter.firstName}`);
      } catch (err) {
        console.error('Erreur lors de la récupération de l\'inviteur :', err.message);
        setError(t('inviter_info_failure'));
      } finally {
        setLoading(false);
      }
    };
    
    

    fetchInviter();
  }, [token, t]);

  const handleLogin = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email');
    const password = data.get('password');

    try {
      await login(email, password);
      window.location.reload(); // Recharge la page pour refléter l'état connecté
    } catch (error) {
      console.error(error);
      setError(error.message || t('login_error'));
    }
  };

  const handleAccept = async () => {
    setLoading(true);
    try {
      const language = localStorage.getItem('i18nextLng') || 'fr';
      const response = await fetch(`${backendUrl}/api/invitations/accept/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ userId: currentUser.userId, language }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || t('accept_invitation_error'));
      }
  
      setSuccess(t('invitation_accepted'));
      setError('');
      setLoading(false);
  
      navigate('/status');
    } catch (err) {
      console.error('Erreur lors de l\'acceptation de l\'invitation :', err.message);
      setError(t('invitation_expired'));
      setSuccess('');
      setLoading(false);
    }
  };
  
  const handleDecline = async () => {
    setLoading(true);
    try {
      const language = localStorage.getItem('i18nextLng') || 'fr';
      const response = await fetch(`${backendUrl}/api/invitations/decline/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ language }),
      });
  
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message || t('decline_invitation_error'));
      }
  
      setSuccess(t('invitation_declined'));
      setError('');
    } catch (err) {
      console.error('Erreur lors du refus de l\'invitation :', err.message);
      setError(t('decline_invitation_failure'));
      setSuccess('');
    } finally {
      setLoading(false);
    }
  };
  

  const handleOpenApp = () => {
    window.location.href = `raoulapp://deep-link/accept-invitation/${token}`;
  };

  return (
    <Box
      sx={{
        backgroundColor: 'secondary.main',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        px: 2,
      }}
    >
      <Box component="img" src="/img/duo.svg" alt="Invitation Duo" width="74vw" />

      {loading && <CircularProgress sx={{ mt: 3 }} />}

      {!loading && (
        <Box textAlign="center" mt={3} width="70%">
          {success && <Typography color="primary" variant="h6">{success}</Typography>}
          {error && <Typography color="error" variant="h6">{error}</Typography>}

          {!success && !error && (
            <>
              {isBrowser ? (
                <>
                  <Typography variant="h5" mb={2}>
                    {t('open_app_to_accept')}
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleOpenApp}
                    sx={{ mt: 2, width: '100%' }}
                  >
                    {t('open_app')}
                  </Button>
                </>
              ) : (
                <>
                  {!currentUser ? (
                    <>
                      <Typography variant="h5" mb={2}>
                        {t('login_to_accept')}
                      </Typography>
                      <Box component="form" onSubmit={handleLogin} sx={{ textAlign: 'center' }}>
                        <TextField
                          fullWidth
                          label={t('email')}
                          type="email"
                          name="email"
                          sx={{ mb: 2 }}
                        />
                        <TextField
                          fullWidth
                          label={t('password')}
                          type={showPassword ? 'text' : 'password'}
                          name="password"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={() => setShowPassword(!showPassword)}
                                  aria-label="toggle password visibility"
                                >
                                  {showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ mb: 2 }}
                        />
                        <Button type="submit" fullWidth variant="contained" sx={{ mt: 2 }}>
                          {t('login')}
                        </Button>
                      </Box>
                    </>
                  ) : (
                    <>
                      <Typography variant="h5" mb={1}>
                        {inviterName ? `${inviterName} ${t('invites_you')}` : t('invitation_received')}
                      </Typography>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAccept}
                        sx={{ mt: 2, width: '100%' }}
                      >
                        {t('accept')}
                      </Button>
                      <Button
                        variant="text"
                        onClick={handleDecline}
                        sx={{ mt: 0.5, width: '100%' }}
                      >
                        {t('decline')}
                      </Button>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      )}
    </Box>
  );
};

export default AcceptInvitationPage;
