import React, { useState, useEffect } from 'react';
import { Box, Alert, Collapse, List, ListItem, ListItemText, Avatar, ListItemAvatar, IconButton, CircularProgress, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { Plugins } from '@capacitor/core';
const { Contacts, Device } = Plugins;
import { useAuth } from '../../../contexts/AuthContext';
import { parsePhoneNumberFromString, AsYouType } from 'libphonenumber-js';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const AddContact = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { currentUser } = useAuth();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [friends, setFriends] = useState([]);
  const [pendingFriends, setPendingFriends] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filteredContacts, setFilteredContacts] = useState([]);

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false);
        setSuccess('');
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  useEffect(() => {
    if (currentUser) {
      if (currentUser.friends) {
        setFriends(currentUser.friends);
      }
      if (currentUser.pendingFriends) {
        setPendingFriends(currentUser.pendingFriends);
      }
    }
  }, [currentUser]);

  useEffect(() => {
    if (contacts && contacts.length > 0 && currentUser) {
      const filtered = contacts.filter(contact => 
        contact.username !== currentUser.username && // Exclure l'utilisateur actif
        !friends.some(friend => friend.username === contact.username) &&
        !pendingFriends.some(pending => pending.username === contact.username)
      );
  
      setFilteredContacts(filtered);
    }
  }, [contacts, friends, pendingFriends, currentUser]);
  
  useEffect(() => {
    const storedContacts = localStorage.getItem('contacts');
    if (storedContacts) {
      setContacts(JSON.parse(storedContacts));
      setLoading(false);
    } else {
      handleRequestPermissions();
    }
  }, []);

  const handleRequestPermissions = async () => {
    try {
      const info = await Device.getInfo();
      if (info.platform === 'ios') {
        const permission = await Contacts.requestPermissions();
        if (permission.contacts === 'granted') {
          fetchContacts();
        } else {
          setPermissionDenied(true);
          setError(t('permission_denied'));
          setLoading(false);
        }
      } else if (info.platform === 'android') {
        const permission = await Contacts.requestPermissions();
        if (permission.contacts === 'granted') {
          fetchContacts();
        } else {
          setPermissionDenied(true);
          setError(t('permission_denied'));
          setLoading(false);
        }
      } else {
        setError(t('platform_not_supported'));
        setShowAlert(true);
        setLoading(false);
      }
    } catch (error) {
      setError(t('permission_request_error'));
      setShowAlert(true);
      setLoading(false);
    }
  };

  const formatPhoneNumber = (phoneNumber, defaultCountry = 'FR') => {
    let formatted = phoneNumber.replace(/\s+/g, '').replace(/[-()]/g, '');
    let phoneNumberObj = parsePhoneNumberFromString(formatted, defaultCountry);
    if (phoneNumberObj && phoneNumberObj.isValid()) {
      return phoneNumberObj.formatInternational().replace(/\s+/g, '');
    }
    return formatted;
  };
  
  const fetchContacts = () => {
    try {
      navigator.contacts.find(
        ['displayName', 'phoneNumbers'],
        (contacts) => {
          const phoneNumbers = contacts
            .filter(contact => contact.phoneNumbers && contact.phoneNumbers.length > 0)
            .map(contact => formatPhoneNumber(contact.phoneNumbers[0].value));

          checkActiveContacts(phoneNumbers, contacts);
        },
        (error) => {
          setError(t('contact_fetch_error'));
          setShowAlert(true);
          setLoading(false);
        }
      );
    } catch (error) {
      setError(t('contact_fetch_error'));
      setShowAlert(true);
      setLoading(false);
    }
  };

  const checkActiveContacts = async (phoneNumbers, contacts) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/check-contacts`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ phoneNumbers })
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t('contact_check_error'));
      }
  
      const activeContacts = await response.json();
      let mergedContacts = activeContacts.map(activeContact => {
        const originalContact = contacts.find(contact => 
          contact.phoneNumbers && contact.phoneNumbers[0] && formatPhoneNumber(contact.phoneNumbers[0].value) === activeContact.phone
        );
        return {
          ...activeContact,
          displayName: originalContact ? `${activeContact.firstName || ''} ${activeContact.lastName || ''}`.trim() : t('name_not_available')
        };
      });
  
      mergedContacts = mergedContacts.filter(contact => 
        contact.username !== currentUser.username && 
        !friends.some(friend => friend.username === contact.username) &&
        !pendingFriends.some(pending => pending.username === contact.username)
      );
      
      localStorage.setItem('contacts', JSON.stringify(mergedContacts));
      setContacts(mergedContacts);
      setSuccess(t('contacts_fetched_successfully'));
      setShowAlert(true);
      setLoading(false);
    } catch (error) {
      setError(t('active_contacts_error'));
      setShowAlert(true);
      setLoading(false);
    }
  };
  
  const handleAddFriend = async (username) => {
    if (friends.some(friend => friend.username === username) || pendingFriends.some(pending => pending.username === username)) {
      setError(t('friend_request_sent_or_already_added'));
      setShowAlert(true);
      return;
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/friend/invite/${username}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}` 
        }
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || t('friend_request_error'));
      }
  
      setSuccess(t('friend_request_sent', { username }));
      setShowAlert(true);
    } catch (error) {
      setError(t('friend_request_error', { error: error.message }));
      setShowAlert(true);
    }
  };

  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Collapse in={showAlert || permissionDenied}>
          {error && <Alert severity="error" onClose={() => setPermissionDenied(false)}>{error}</Alert>}
          {success && <Alert severity="success" onClose={() => setShowAlert(false)}>{success}</Alert>}
        </Collapse>
      </Box>
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" height="20vh">
          <CircularProgress />
        </Box>
      ) : (
      <List sx={{ p: 0 }}>
        {(filteredContacts && filteredContacts.length > 0 ? filteredContacts : contacts).map((contact, index) => (
          <ListItem sx={{ p: 0 }} key={index}>
            <ListItemAvatar>
              <Avatar sx={{ border: '1px solid #ddd' }} alt={contact.username} src={contact.avatar} />
            </ListItemAvatar>
            <ListItemText
              primary={`${contact.firstName || ''} ${contact.lastName || ''}`.trim()}
              secondary={contact.username || t('username_not_available')}
            />
            <IconButton
              sx={{ backgroundColor: 'black', color: 'white', '&:hover': { backgroundColor: 'black' } }}
              onClick={() => handleAddFriend(contact.username)}
            >
              <AddIcon />
            </IconButton>
          </ListItem>
        ))}
      </List>
      )}
    </Box>
  );
};

export default AddContact;
