import React, { useState, useEffect } from 'react';
import { Box, Drawer, Typography, List, ListItem, Button, TextField, Switch, FormControlLabel } from '@mui/material';
import { useAuth } from '../../contexts/AuthContext';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const EditNotification = ({ isOpen, onClose }) => {
  const { t } = useTranslation(); // Hook pour les traductions
  const { currentUser, updateUser } = useAuth();
  const [meditationReminderEnabled, setMeditationReminderEnabled] = useState(false);
  const [meditationReminderTime, setMeditationReminderTime] = useState('08:00');
  const [globalNotificationsEnabled, setGlobalNotificationsEnabled] = useState(false);

  useEffect(() => {
    if (currentUser && currentUser.notificationPreferences) {
      const { meditationReminder, globalNotifications } = currentUser.notificationPreferences;
      setMeditationReminderEnabled(meditationReminder ? meditationReminder.enabled : false);
      setMeditationReminderTime(meditationReminder ? meditationReminder.time : '08:00');
      setGlobalNotificationsEnabled(globalNotifications || false);
    }
  }, [currentUser]);

  const handleSave = async () => {
    const preferences = {
      notificationPreferences: {
        meditationReminder: {
          enabled: meditationReminderEnabled,
          time: meditationReminderTime
        },
        globalNotifications: globalNotificationsEnabled
      }
    };
    await updateUser(preferences);
    onClose();
  };

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box sx={{ width: 300, p: 3 }}>
        <Typography variant="h6">{t('notification_settings')}</Typography>
        <List>
          <ListItem>
            <FormControlLabel
              control={<Switch checked={meditationReminderEnabled} onChange={(e) => setMeditationReminderEnabled(e.target.checked)} />}
              label={t('daily_meditation_reminder')}
            />
          </ListItem>
          {meditationReminderEnabled && (
            <ListItem>
              <TextField
                label={t('reminder_time')}
                type="time"
                value={meditationReminderTime}
                onChange={(e) => setMeditationReminderTime(e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{ step: 300 }} // 5 min
              />
            </ListItem>
          )}
          <ListItem>
            <FormControlLabel
              control={<Switch checked={globalNotificationsEnabled} onChange={(e) => setGlobalNotificationsEnabled(e.target.checked)} />}
              label={t('global_notifications')}
            />
          </ListItem>
        </List>
        <Button variant="contained" onClick={handleSave}>{t('save')}</Button>
      </Box>
    </Drawer>
  );
};

export default EditNotification;
