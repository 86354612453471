import React, { useState, useEffect } from 'react';
import { Box, Skeleton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'; // Importer le hook pour les traductions
import MeditationCardComponent from './MeditationCardComponent';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TechniqueListComponent = () => {
  const { t, i18n } = useTranslation(); // Utiliser useTranslation pour la langue active
  const navigate = useNavigate();
  const [techniques, setTechniques] = useState([]);
  const [loading, setLoading] = useState(true); // État de chargement

  useEffect(() => {
    const fetchTechniques = async () => {
      try {
        const response = await fetch(`${backendUrl}/api/techniques/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
          },
        });
        if (!response.ok) {
          throw new Error('La récupération des techniques a échoué');
        }
        const data = await response.json();
        setTechniques(data);
      } catch (error) {
        console.error(error.message);
      } finally {
        setLoading(false); // Désactiver le chargement après récupération
      }
    };

    fetchTechniques();
  }, []);

  const handleCardClick = (id) => {
    navigate(`/technique/${id}`);
  };

  if (loading) {
    // Afficher des Skeletons pendant le chargement
    return (
      <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
        {[1, 2, 3, 4].map((item) => (
          <Box key={item} sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Skeleton variant="rectangular" height={140} />
            <Skeleton variant="text" width="80%" />
            <Skeleton variant="text" width="60%" />
          </Box>
        ))}
      </Box>
    );
  }

  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: 2 }}>
      {techniques.map((technique) => {
        const title = technique.title ? technique.title[i18n.language] || technique.title['fr'] : 'N/A';
        const description = technique.description ? technique.description[i18n.language] || technique.description['fr'] : 'N/A';
        const audioUrl = technique.audioUrl ? technique.audioUrl[i18n.language] || technique.audioUrl['fr'] : 'N/A';
        const facts = technique.facts ? technique.facts[i18n.language] || technique.facts['fr'] : 'N/A';

        return (
          <MeditationCardComponent
            key={technique._id}
            image={technique.image || ''}
            title={title} // Titre traduit ou par défaut
            category={technique.category ? technique.category[i18n.language] || technique.category['fr'] : 'N/A'} // Catégorie traduite ou par défaut
            duration={technique.duration}
            description={description} // Description traduite ou par défaut
            istechniqueList={true}
            showIcon={false}
            onClick={() => handleCardClick(technique._id)}
          />
        );
      })}
    </Box>
  );
};

export default TechniqueListComponent;
