import React, { useState } from 'react';
import { Box, Typography, Button, Card, CardContent, AppBar, Toolbar, Drawer, IconButton, Avatar } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useDeviceInfo from '../../utils/useDeviceInfo';
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import GroupIcon from '@mui/icons-material/Group';
import SpaIcon from '@mui/icons-material/Spa';
import EmojiPeopleIcon from '@mui/icons-material/EmojiPeople';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const AdsDuoPage = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const navigate = useNavigate();
  const [openDrawer1, setOpenDrawer1] = useState(false);
  const [openDrawer2, setOpenDrawer2] = useState(false);
  const [openDrawer3, setOpenDrawer3] = useState(false);
  const { paddingTop, paddingBottom } = useDeviceInfo();

  const handleSubscriptionClick = () => {
    navigate('/subscribe');
  };

  const handleBackClick = () => {
    navigate('/today');
  };

  return (
    <>
      {/* Toolbar avec bouton de retour */}
      <AppBar position="static" sx={{ backgroundColor: 'transparent', boxShadow: 'none' }}>
        <Toolbar sx={{ pt: paddingTop + 2, px: 2 }}>
          <IconButton
            size="small"
            sx={{
              p: 0.5,
              border: '1px solid #ddd',
              borderRadius: '50%',
              mr: 2,
            }}
            aria-label="retour"
            onClick={handleBackClick}
          >
            <ArrowBackIosRoundedIcon fontSize="small" />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          px: 3,
          minHeight: '100vh',
          textAlign: 'center',
          pb: 15,
        }}
      >
        <Box component="img" src="/img/duo.svg" alt="Méditation" width="70vw" />

        {/* Section Titre et Accroche Duo */}
        <Typography variant="h4" sx={{ fontWeight: 'bold', mt: 2, mb: 2 }}>
          {t('meditate_in_pair')}
        </Typography>
        <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, backgroundColor: 'secondary.main', px: 2, py: 1, borderRadius: 2 }}>
          1 {t('subscription')} = 2 {t('accesses')}
        </Typography>
        <Typography variant="body1" sx={{ mb: 2.5 }}>
          {t('for_399_per_month')}
        </Typography>

        {/* Section des Bienfaits en Duo */}
        <Card elevation="0" sx={{ maxWidth: 600, mb: 4, border: '1px solid #DDD', borderRadius: 2, textAlign: 'left', p: 1 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              {t('why_meditate_in_pair')}
            </Typography>

            <Box
              sx={{ display: 'flex', alignItems: 'center', mb: 2, cursor: 'pointer' }}
              onClick={() => setOpenDrawer1(true)}
            >
              <Avatar sx={{ bgcolor: 'secondary.main', mr: 2, width: 32, height: 32 }}>
                <GroupIcon fontSize="small" sx={{ color: 'primary.main' }} />
              </Avatar>
              <Typography variant="body2">
                {t('pair_study_1')}
              </Typography>
            </Box>

            <Box
              sx={{ display: 'flex', alignItems: 'center', mb: 2, cursor: 'pointer' }}
              onClick={() => setOpenDrawer2(true)}
            >
              <Avatar sx={{ bgcolor: 'secondary.main', mr: 2, width: 32, height: 32 }}>
                <SpaIcon fontSize="small" sx={{ color: 'primary.main' }} />
              </Avatar>
              <Typography variant="body2">
                {t('pair_study_2')}
              </Typography>
            </Box>

            <Box
              sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
              onClick={() => setOpenDrawer3(true)}
            >
              <Avatar sx={{ bgcolor: 'secondary.main', mr: 2, width: 32, height: 32 }}>
                <EmojiPeopleIcon fontSize="small" sx={{ color: 'primary.main' }} />
              </Avatar>
              <Typography variant="body2">
                {t('pair_study_3')}
              </Typography>
            </Box>
          </CardContent>
        </Card>

        {/* Section 1+1 = 2 */}
        <Box
          sx={{
            position: 'fixed',
            bottom: 0,
            background: 'white',
            px: 3,
            pt: 3,
            pb: paddingBottom + 2,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            sx={{
              fontSize: '1.2em',
              fontWeight: 'bold',
              padding: '10px 20px',
            }}
            onClick={handleSubscriptionClick}
          >
            {t('try_14_days')}
          </Button>
          <Button
            variant="text"
            sx={{ textDecoration: 'underline' }}
            onClick={() => navigate(-1)}
          >
            {t('prefer_meditate_alone')}
          </Button>
        </Box>

        {/* Drawers pour les sources */}
        <Drawer anchor="bottom" open={openDrawer1} onClose={() => setOpenDrawer1(false)}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6">Source :</Typography>
            <Typography variant="body">
              {t('study_1')}
            </Typography>
          </Box>
        </Drawer>

        <Drawer anchor="bottom" open={openDrawer2} onClose={() => setOpenDrawer2(false)}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6">Source :</Typography>
            <Typography variant="body">
              {t('study_2')}
            </Typography>
          </Box>
        </Drawer>

        <Drawer anchor="bottom" open={openDrawer3} onClose={() => setOpenDrawer3(false)}>
          <Box sx={{ p: 3 }}>
            <Typography variant="h6">Source :</Typography>
            <Typography variant="body">
              {t('study_3')}
            </Typography>
          </Box>
        </Drawer>
      </Box>
    </>
  );
};

export default AdsDuoPage;
