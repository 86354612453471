import React, { useState, useEffect } from 'react';
import Lottie from 'react-lottie-player';
import animationData from '../../assets/pulse.json';
import { Box, Typography } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const WelcomeSlide = () => {
  const { t } = useTranslation(); // Hook pour les traductions
  const [currentText, setCurrentText] = useState('');
  const [fadeIn, setFadeIn] = useState(false);

  return (
    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100vw', bgcolor: 'secondary.main', color: 'black', textAlign: 'center', position: 'relative', overflow: 'hidden' }}>
      <Box
        sx={{
          position: 'absolute',
          width: '1100px',
          height: '1100px',
          opacity: 1, // Always visible
          transition: 'opacity 0.5s ease-in-out',
        }}
      >
        <Lottie
          loop
          animationData={animationData}
          play
          speed={0.4}
          style={{ width: '100%', height: '100%' }}
        />
      </Box>
      <Box sx={{ zIndex: 100 }}>
          <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }} variant="h5">
            <CheckCircleRoundedIcon /> {t('subscription_validated')}
          </Typography>
      </Box>
    </Box>
  );
};

export default WelcomeSlide;
