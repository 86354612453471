import React from 'react';
import { Box } from '@mui/material';
import AdminNavBarComponent from '../../components/admin/AdminNavBarComponent'; 
import ReviewManagementComponent from '../../components/admin/ReviewManagementComponent'; 

const AdminReviews = () => {
  return (
    <Box>
      <AdminNavBarComponent />
      <ReviewManagementComponent />
    </Box>
  );
};

export default AdminReviews;
