import React, { useState, useEffect } from 'react';
import {
  Button, Card, CardContent, Drawer, IconButton, Typography,
  Grid, Box, TextField
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import DOMPurify from 'dompurify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import styles

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const TechniqueManagementComponent = () => {
  const [techniques, setTechniques] = useState([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [currentTechniqueToEdit, setCurrentTechniqueToEdit] = useState(null);

  useEffect(() => {
    fetchTechniques();
  }, []);

  const fetchTechniques = async () => {
    const response = await fetch(`${backendUrl}/api/techniques`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`,
      },
    });
    if (response.ok) {
      const data = await response.json();
      setTechniques(data);
    } else {
      console.error('Erreur lors de la récupération des techniques');
    }
  };

  const handleEdit = (technique) => {
    setCurrentTechniqueToEdit(technique);
    setIsDrawerOpen(true);
  };

  const handleDelete = async (techniqueId) => {
    if (window.confirm('Êtes-vous sûr de vouloir supprimer cette technique ?')) {
      const token = localStorage.getItem('token');
      const response = await fetch(`${backendUrl}/api/techniques/${techniqueId}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      if (response.ok) {
        setTechniques(techniques.filter(technique => technique._id !== techniqueId));
      } else {
        console.error('Erreur lors de la suppression de la technique');
      }
    }
  };

  const handleAddTechnique = () => {
    setCurrentTechniqueToEdit(null);
    setIsDrawerOpen(true);
  };

  const handleSaveTechnique = async (formData) => {
    const token = localStorage.getItem('token');
    let url = `${backendUrl}/api/techniques`;
    let method = 'POST';
    if (formData._id) {
      url += `/${formData._id}`;
      method = 'PUT';
    }
    const body = JSON.stringify(formData);

    const response = await fetch(url, {
      method,
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body,
    });

    if (response.ok) {
      fetchTechniques(); // Re-fetch the list to reflect changes
      setIsDrawerOpen(false);
    } else {
      console.error('Erreur lors de la sauvegarde de la technique');
    }
  };

  const TechniqueFormDrawer = ({ isOpen, onClose, technique }) => {
    const [formData, setFormData] = useState({
      title: { fr: '', en: '' },
      description: { fr: '', en: '' },
      facts: { fr: '', en: '' },
      duration: 0,
      audioUrl: { fr: '', en: '' },
      image: '',
      playCount: 0,
      ...technique,
    });

    useEffect(() => {
      if (technique) {
        setFormData(technique);
      }
    }, [technique]);

    const handleChange = (e) => {
      const { name, value } = e.target;
      const [field, lang] = name.split('.'); // Sépare le nom du champ et la langue

      if (lang) {
        // Si c'est un champ multilingue (comme title.fr ou title.en)
        setFormData(prevState => ({
          ...prevState,
          [field]: {
            ...prevState[field],
            [lang]: value,
          },
        }));
      } else {
        // Si c'est un champ simple (comme duration ou image)
        setFormData(prevState => ({
          ...prevState,
          [name]: value,
        }));
      }
    };

    const handleFactsChange = (value, lang) => {
      setFormData(prevState => ({
        ...prevState,
        facts: {
          ...prevState.facts,
          [lang]: value,
        },
      }));
    };

    const handleSubmit = (e) => {
      e.preventDefault();
      handleSaveTechnique(formData);
      onClose();
    };

    return (
      <Drawer anchor="bottom" open={isOpen} onClose={onClose}>
        <Box p={2} component="form" onSubmit={handleSubmit}>
          <TextField
            label="Titre (Français)"
            name="title.fr"
            value={formData.title.fr || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Titre (Anglais)"
            name="title.en"
            value={formData.title.en || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description (Français)"
            name="description.fr"
            value={formData.description.fr || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="Description (Anglais)"
            name="description.en"
            value={formData.description.en || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Typography variant="h6" gutterBottom>Facts (Français)</Typography>
          <ReactQuill
            value={formData.facts.fr || ''}
            onChange={(value) => handleFactsChange(value, 'fr')}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'},
                 {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
                ['clean']
              ],
            }}
          />
          <Typography variant="h6" gutterBottom>Facts (Anglais)</Typography>
          <ReactQuill
            value={formData.facts.en || ''}
            onChange={(value) => handleFactsChange(value, 'en')}
            modules={{
              toolbar: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'},
                 {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image'],
                ['clean']
              ],
            }}
          />
          <TextField
            label="Durée (minutes)"
            name="duration"
            type="number"
            value={formData.duration || 0}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="URL Audio (Français)"
            name="audioUrl.fr"
            value={formData.audioUrl.fr || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="URL Audio (Anglais)"
            name="audioUrl.en"
            value={formData.audioUrl.en || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            label="URL de l'Image"
            name="image"
            value={formData.image || ''}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <TextField
            disabled
            label="Nombre de lectures"
            name="playCount"
            type="number"
            value={formData.playCount || 0}
            onChange={handleChange}
            fullWidth
            margin="normal"
          />
          <Button type="submit" sx={{ mt: 2 }} variant="contained">
            Sauvegarder
          </Button>
        </Box>
      </Drawer>
    );
  };

  return (
    <>
      <Box sx={{ margin: 2 }}>
        <Button startIcon={<AddIcon />} onClick={handleAddTechnique} variant="contained">
          Ajouter une technique
        </Button>
      </Box>
      <Box sx={{ ml: 2, mr: 2, mb: 4 }}>
        <Grid container spacing={2}>
          {techniques.map(technique => (
            <Grid item xs={12} sm={6} md={4} key={technique._id}>
              <Card elevation={0} sx={{ pl: 1, pt: 1, borderRadius: '8px', border: '1px solid', borderColor: 'grey.300', overflow: 'hidden' }}>
                <CardContent>
                  <Typography variant="h6" component="h2">
                    {technique.title.fr} {/* Affiche le titre en français */}
                  </Typography>
                  <Typography color="text.secondary" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(technique.description.fr) }} />
                  <Box>
                    <IconButton onClick={() => handleEdit(technique)} size="small">
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDelete(technique._id)} size="small">
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
      {isDrawerOpen && (
        <TechniqueFormDrawer
          isOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          technique={currentTechniqueToEdit}
        />
      )}
    </>
  );
};

export default TechniqueManagementComponent;