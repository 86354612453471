import React, { useState, useEffect } from 'react';
import { Box, List, ListItem, Avatar, Typography, Paper } from '@mui/material';
import AutoDeleteRoundedIcon from '@mui/icons-material/AutoDeleteRounded';
import { useTranslation } from 'react-i18next'; // Importer le hook i18n

const MessagesList = ({ messages, currentUser, connectedUsers, inviteMessageRef, messagesEndRef, roomCode, isInviteCardVisible }) => {
  const [loading, setLoading] = useState(true);
  const { t } = useTranslation(); // Hook pour les traductions

  const getSenderName = (userId) => {
    return connectedUsers[userId]?.name || t('unknown_user');
  };

  const getSenderAvatar = (userId) => {
    const profileImage = connectedUsers[userId]?.profileImage;
  
    const avatar = profileImage && (profileImage.startsWith('http') || profileImage.startsWith('https'))
      ? profileImage
      : `/${profileImage || 'avatar/default.jpg'}`;
  
    return avatar;
  };

  useEffect(() => {
    setLoading(false);
  }, []);

  return (
    <>
      {!loading && (
        <>
          {!isInviteCardVisible && messages.length === 0 && (
            <Box sx={{ textAlign: 'center', px: 10, margin: 'auto auto', mt: 32 }}>
              <AutoDeleteRoundedIcon sx={{ fontSize: 20, color: 'black' }} />
              <Typography variant="body2" sx={{ mb: 0.5, fontWeight: 'bold' }}>
                {t('ephemeral_conversation')}
              </Typography>
              <Typography variant="body2" sx={{ fontSize: '0.8em' }}>
                {t('messages_delete_at_midnight')}
              </Typography>
            </Box>
          )}

          <Box sx={{ pb: 9, px: 2 }}>
            <List sx={{ padding: 0 }}>
              {messages.map((msg, index) => {
                const isCurrentUser = msg.sender === currentUser.userId;
                const showAvatarAndName = index === 0 || messages[index - 1].sender !== msg.sender;

                let parsedMessage;
                try {
                  parsedMessage = JSON.parse(msg.message);
                } catch (error) {
                  parsedMessage = { text: msg.message };
                }

                // Détection de changement d'expéditeur
                const isDifferentSender = index > 0 && messages[index - 1].sender !== msg.sender;

                return (
                  <React.Fragment key={index}>
                    {/* Ajout d'espace entre les groupes d'expéditeurs */}
                    {isDifferentSender && <Box sx={{ height: '20px' }} />} 

                    {/* Affichage du nom de l'expéditeur */}
                    {!isCurrentUser && showAvatarAndName && (
                      <Typography
                        variant="subtitle2"
                        color="textSecondary"
                        sx={{ alignSelf: 'flex-start', margin: '10px 0 0 50px', mb: '5px', fontSize: '0.8em' }}
                      >
                        {getSenderName(msg.sender)}
                      </Typography>
                    )}

                    {/* Message */}
                    <ListItem
                      sx={{
                        display: 'flex',
                        flexDirection: isCurrentUser ? 'row-reverse' : 'row',
                        alignItems: 'flex-start',
                        padding: '0px 0',
                        paddingLeft: showAvatarAndName ? '0' : '40px',
                      }}
                    >
                      {/* Avatar */}
                      {!isCurrentUser && showAvatarAndName && (
                        <Avatar
                          src={getSenderAvatar(msg.sender)}
                          sx={{ width: 32, height: 32, margin: '0 8px 0 0', border: '1px solid #DDD' }}
                        />
                      )}
                      {/* Bulle de message */}
                      <Paper
                        sx={{
                          boxShadow: 'none',
                          backgroundColor: isCurrentUser ? 'primary.main' : '#EEE',
                          color: isCurrentUser ? '#FFF' : '#000',
                          borderRadius: '20px',
                          padding: '8px 16px',
                          position: 'relative',
                          marginBottom: '5px',
                          marginRight: isCurrentUser ? '0' : '40px',
                        }}
                      >
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', maxWidth: '100%' }}>
                          <Typography variant="body2" color="inherit" component="span" sx={{ lineHeight: '1.4em', wordBreak: 'break-word' }}>
                            {parsedMessage.text || msg.message}
                          </Typography>
                          <Typography sx={{ fontSize: '0.65rem', color: isCurrentUser ? '#FFF' : '#000', marginLeft: '8px' }}>
                            {new Date(msg.timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                          </Typography>
                        </Box>
                      </Paper>
                    </ListItem>
                  </React.Fragment>
                );
              })}
              <div ref={messagesEndRef} />
            </List>
          </Box>
        </>
      )}
    </>
  );
};

export default MessagesList;
